import React, { useCallback } from "react";
import PropTypes from "prop-types";
import NumberIncrementor from "./NumberIncrementor";

export default function PropertyGoodsServices(props) {
  const {
    categories,
    services,
    invoice_services,
    sub_merchants,
    category_services,
    onServiceQuantityChange,
    activeCategoryId,
    readonly,
  } = props;

  const filteredCategories =
    activeCategoryId === null ? categories : categories.filter((category) => category.id === activeCategoryId);

  const getSubmerchantDescription = useCallback(
    (sub_merchant_id) => {
      return sub_merchants[sub_merchant_id] || "(Account removed from building)";
    },
    [sub_merchants],
  );

  return (
    <>
      {filteredCategories.map((category) => (
        <div className="goods-services__category" key={category.id}>
          <div className="goods-services__category-header">
            <span className="goods-services__category-header-text">{category.title}</span>
          </div>
          <div className="goods-services__category-services">
            <table className="table" rules="none">
              <thead>
                <tr>
                  <th className="col-xs-4">Item</th>
                  <th className="col-xs-4">Routing Account</th>
                  <th className="col-xs-2">Price/Unit</th>
                  <th className="col-xs-2">Quantity</th>
                </tr>
              </thead>
              <tbody>
                {(category_services[category.id] || []).map((service_id) => {
                  const service = services[service_id];
                  const quantity = invoice_services[service.id]?.quantity || 0;
                  const classes = [];

                  if (quantity > 0) classes.push("added");

                  return (
                    <tr key={service.id} className={classes.join(" ")}>
                      <td>{service.title}</td>
                      <td>{getSubmerchantDescription(service.sub_merchant_id)}</td>
                      <td>{asMoney(service.price / 100)}</td>
                      <td>
                        <NumberIncrementor
                          min={0}
                          value={quantity}
                          onChange={(value) => onServiceQuantityChange(service.id, value)}
                          disabled={readonly}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </>
  );
}

PropertyGoodsServices.defaultProps = {
  readonly: false,
};

PropertyGoodsServices.propTypes = {
  activeCategoryId: PropTypes.number,
  invoice: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    building_id: PropTypes.number.isRequired,
    organization_id: PropTypes.number,
    user_id: PropTypes.number,
    requested_by_id: PropTypes.number.isRequired,
    building: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
    }).isRequired,
    unit: PropTypes.string.isRequired,
    note: PropTypes.string,
  }),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      building_id: PropTypes.number.isRequired,
      organization_id: PropTypes.number,
    }),
  ),
  services: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      building_id: PropTypes.number.isRequired,
      organization_id: PropTypes.number,
      category_id: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
      active: PropTypes.bool.isRequired,
    }),
  ),
  invoice_services: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      invoice_id: PropTypes.number.isRequired,
      service_id: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
    }),
  ),
  sub_merchants: PropTypes.object,
  category_services: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.number)),
  onServiceQuantityChange: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
};
