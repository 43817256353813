import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "components/utility/Tooltip";
import NumberIncrementor from "./NumberIncrementor";
import ConfirmPaymentRequestModal from "./modals/ConfirmPaymentRequestModal";
import PaymentRequestSentModal from "./modals/PaymentRequestSentModal";
import CancelInvoiceModal from "./modals/CancelInvoiceModal";
import { cancelInvoice, selectInvoicePageState, shareInvoice } from "./store/slice";
import InvoiceEdit from "../InvoiceEdit";
import InvoiceCancelledModal from "./modals/InvoiceCancelledModal";

export default function InvoiceSummary(props) {
  const dispatch = useDispatch();

  const {
    services,
    invoice,
    invoice_services,
    onServiceQuantityChange,
    onInvoiceSent,
    readonly,
    addedServiceIds,
  } = props;

  const { cancelledInvoiceAddress, invoiceCancelled } = useSelector(selectInvoicePageState);

  let subTotal = 0;

  addedServiceIds.forEach((service_id) => {
    const invoice_service = invoice_services[service_id];
    const service = services[invoice_service.service_id];

    subTotal += invoice_service.quantity * service.price;
  });

  const appAdmin = Math.ceil(subTotal * 0.05);
  const total = subTotal + appAdmin;

  const goToInvoicesStatusPage = () => {
    window.location.href = "/resident_payments/invoices";
  };

  const [confirmPaymentRequestModalVisible, setConfirmPaymentRequestModalVisible] = useState(false);
  const openConfirmPaymentRequestModal = () => setConfirmPaymentRequestModalVisible(true);
  const closeConfirmPaymentRequestModal = () => setConfirmPaymentRequestModalVisible(false);

  const [paymentRequestSentModalVisible, setPaymentRequestSentModalVisible] = useState(false);
  const openPaymentRequestSentModal = () => setPaymentRequestSentModalVisible(true);
  const closePaymentRequestSentModal = () => {
    setPaymentRequestSentModalVisible(false);
    goToInvoicesStatusPage();
  };

  const onSendPaymentRequest = (note) => {
    dispatch(shareInvoice({ invoice_id: invoice.id, note: note }));
    closeConfirmPaymentRequestModal();
    openPaymentRequestSentModal();
    onInvoiceSent();
  };

  // TODO: Extract cancel invoice logic into a separate component
  const [cancelInvoiceModalVisible, setCancelInvoiceModalVisible] = useState(false);
  const openCancelInvoiceModal = () => setCancelInvoiceModalVisible(true);
  const closeCancelInvoiceModal = () => setCancelInvoiceModalVisible(false);
  const onCancelInvoice = () => {
    dispatch(cancelInvoice({ invoice_id: invoice.id }));
  };

  const [invoiceCancelledModalVisible, setInvoiceCancelledModalVisible] = useState(false);
  const openInvoiceCancelledModal = () => setInvoiceCancelledModalVisible(true);
  const closeInvoiceCancelledModal = () => {
    setInvoiceCancelledModalVisible(false);
    goToInvoicesStatusPage();
  };

  useEffect(() => {
    if (invoiceCancelled) {
      closeCancelInvoiceModal();
      openInvoiceCancelledModal();
    }
  }, [invoiceCancelled]);

  return (
    <>
      <div className="invoice-summary__header">
        <span className="invoice-summary__header-text">Invoice Summary</span>
      </div>
      <div className="invoice-summary__body row">
        <div className="invoice-summary__items col-xs-11">
          <div className="invoice-summary__item">
            <div className="invoice-summary__item-title">Property</div>
            <div className="invoice-summary__item-detail">
              <div className="invoice-summary__item-detail-title">{invoice.building_address}</div>
              <div className="invoice-summary__item-detail-subtitle">Unit {invoice.unit}</div>
            </div>
          </div>
          <div className="invoice-summary__item">
            <div className="invoice-summary__item-title">Resident</div>
            <div className="invoice-summary__item-detail">
              <div className="invoice-summary__item-detail-title">
                {invoice.first_name} {invoice.last_name}
              </div>
              <div className="invoice-summary__item-detail-subtitle">{invoice.email}</div>
            </div>
          </div>
        </div>
        <div className="invoice-summary__edit col-xs-1">
          <InvoiceEdit invoice={invoice} disabled={readonly}>
            <i className="fa-solid fa-pencil"></i>
          </InvoiceEdit>
        </div>
      </div>
      {addedServiceIds.length === 0 ? (
        <div className="invoice-summary__empty-cart">
          <span>Add an item to cart to submit request.</span>
        </div>
      ) : (
        <>
          <div className="invoice-summary__cart">
            {addedServiceIds.map((service_id) => {
              const invoice_service = invoice_services[service_id];
              const service = services[invoice_service.service_id];
              const handleRemove = () => {
                if (!readonly) onServiceQuantityChange(service.id, 0);
              };

              return (
                <div className="invoice-summary__cart-item" key={service_id}>
                  <div className="row invoice-summary__cart-item-detail">
                    <div className="col-xs-6 invoice-summary__cart-item-name">{service.title}</div>
                    <div className="col-xs-3 text-right">
                      <NumberIncrementor
                        min={1}
                        value={invoice_service.quantity}
                        onChange={(value) => onServiceQuantityChange(service.id, value)}
                        disabled={readonly}
                      />
                    </div>
                    <div className="col-xs-3 invoice-summary__cart-item-total text-right">
                      {asMoney((service.price * invoice_service.quantity) / 100)}
                    </div>
                  </div>
                  <div className="invoice-summary__cart-item-remove">
                    <a onClick={handleRemove}>Remove</a>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row invoice-summary__subtotals">
            <div className="col-xs-offset-6 col-xs-6 invoice-summary__subtotals-fees">
              <div className="row invoice-summary__subtotals-fee">
                <div className="col-xs-6 text-right">Subtotal</div>
                <div className="col-xs-6 text-right">{asMoney(subTotal / 100)}</div>
              </div>
              <div className="row invoice-summary__subtotals-fee">
                <div className="col-xs-6 text-right">
                  <Tooltip
                    placement="bottom"
                    title="This charge is for the costs related to facilitating this digital transaction."
                  >
                    <i className="fa-light fa-circle-question"></i>
                  </Tooltip>
                  &nbsp;App Admin
                </div>
                <div className="col-xs-6 text-right">{asMoney(appAdmin / 100)}</div>
              </div>
            </div>
          </div>
          <div className="row invoice-summary__totals">
            <div className="col-xs-offset-6 col-xs-6 invoice-summary__totals-total">
              <div className="row">
                <div className="col-xs-6 text-right">Total</div>
                <div className="col-xs-6 text-right">{asMoney(total / 100)}</div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="invoice-summary__buttons">
        <button className="btn btn-custom-cancel" disabled={readonly} onClick={openCancelInvoiceModal}>
          Cancel Invoice
        </button>
        <button
          className="btn btn-custom-submit"
          disabled={readonly || addedServiceIds.length === 0}
          onClick={openConfirmPaymentRequestModal}
        >
          Send Invoice
        </button>
      </div>
      <ConfirmPaymentRequestModal
        open={confirmPaymentRequestModalVisible}
        onClose={closeConfirmPaymentRequestModal}
        invoice={invoice}
        total={total}
        onSubmit={onSendPaymentRequest}
      />
      <PaymentRequestSentModal
        open={paymentRequestSentModalVisible}
        onClose={closePaymentRequestSentModal}
        email={invoice.email}
      />
      <CancelInvoiceModal
        open={cancelInvoiceModalVisible}
        onClose={closeCancelInvoiceModal}
        onSubmit={onCancelInvoice}
      />
      <InvoiceCancelledModal
        open={invoiceCancelledModalVisible}
        onClose={closeInvoiceCancelledModal}
        invoiceAddress={cancelledInvoiceAddress || ""}
      />
    </>
  );
}

InvoiceSummary.defaultProps = {
  readonly: false,
};

InvoiceSummary.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    building_id: PropTypes.number.isRequired,
    organization_id: PropTypes.number,
    user_id: PropTypes.number,
    requested_by_id: PropTypes.number.isRequired,
    building: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
    }).isRequired,
    unit: PropTypes.string.isRequired,
    note: PropTypes.string,
  }),
  services: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      building_id: PropTypes.number.isRequired,
      organization_id: PropTypes.number,
      category_id: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
      active: PropTypes.bool.isRequired,
    })
  ),
  invoice_services: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      invoice_id: PropTypes.number.isRequired,
      service_id: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
    })
  ),
  onInvoiceSent: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  addedServiceIds: PropTypes.array.isRequired,
  onServiceQuantityChange: PropTypes.func.isRequired,
};
