import React from 'react'

const RenderConfirmModal = ({ text, cleanup, submitFeeAction }) => {
  return (
    <div>
      <i className='fa-thin fa-circle-exclamation fee-withdraw-icon' />
      <p className="fee-modal-header">Warning!</p>
      <p className="fee-modal-sub-header">Are you sure you want to {text} the selected fee(s)?</p>
      <div className="fee-buttons" style={{ marginTop: "3rem" }}>
        <button className="btn btn-danger" onClick={cleanup}>No</button>
        <button className="btn btn-success" onClick={submitFeeAction}>Yes</button>
      </div>
    </div>
  )
}

export default RenderConfirmModal
