import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DomecileInterstitial from 'components/utility/domecile_interstitial'
import { Portal } from 'components/utility/portal'
import {
  resetStateWithProperty as resetStateWithPropertyAction,
  selectInsuranceQuoteInterstitialState,
  setFormConstants as setFormConstantsAction,
  setCloseConfirmModalVisibility as setCloseConfirmModalVisibilityAction,
  setServerErrorModalVisibility as setServerErrorModalVisibilityAction,
  INSURANCE_QUOTE_STEPS,
  setQuoteOfflineSubmittedVisibility as setQuoteOfflineSubmittedVisibilityAction,
} from './slice/slice'

import Main from './components/Main'
import Sidebar from './components/Sidebar'
import CloseButton from './components/CloseButton'
import Loading from './components/Loading'
import { isEmpty } from 'lodash-es'
import ConfirmCloseInterstitialModal from './components/modals/ConfirmCloseInterstitialModal'
import ServerErrorModal from './components/modals/ServerErrorModal'
import QuoteSentPage from './components/QuoteSentPage'

function QuoteInterstitial({ show: interstitialVisible, hiding, onClose, constant_key, constants }) {
  const dispatch = useDispatch()
  const {
    step,
    fullScreenLoading: { visible: loadingVisible, message: loadingMessage },
    closeConfirmModalVisibility,
    serverErrorModalVisibility,
    serverError,
    quoteOfflineSubmittedVisibility
  } = useSelector(selectInsuranceQuoteInterstitialState)

  useEffect(() => {
    const formConstants = isEmpty(constants) ? window[constant_key] : constants

    dispatch(setFormConstantsAction(formConstants))
  }, [constant_key, constants])

  useEffect(() => {
    if (interstitialVisible && quoteOfflineSubmittedVisibility) {
      onClose()
    }
  }, [quoteOfflineSubmittedVisibility, interstitialVisible])
  

  const onCloseButtonClick = () => {
    if (step === INSURANCE_QUOTE_STEPS.quote_sent_page) {
      onClose()
    } else {
      dispatch(setCloseConfirmModalVisibilityAction(true))
    }
  }

  const handleCancelClose = () => {
    dispatch(setCloseConfirmModalVisibilityAction(false))
  }

  const handleConfirmClose = () => {
    handleCancelClose()
    onClose()
  }

  const handleCloseServerErrorModal = () => {
    dispatch(setServerErrorModalVisibilityAction(false))
  }

  useEffect(() => {
    if (interstitialVisible) {
      dispatch(setServerErrorModalVisibilityAction(serverError))
    }
  }, [dispatch, serverError])

  const renderInterstitialContent = () => {
    if (!interstitialVisible && !hiding)
      return (
        <div className="insurance-quote-interstitial__wrapper">
          <div className="insurance-quote-interstitial__sidebar"></div> {/* Preload sidebar background */}
        </div>
      )

    return (
      <>
        <div className="insurance-quote-interstitial__wrapper">
          {step == INSURANCE_QUOTE_STEPS.quote_sent_page ? (
            <QuoteSentPage onClose={onClose} />
          ) : (
            <>
              <Sidebar step={step} loadingVisible={loadingVisible} />
              <Main step={step} loadingVisible={loadingVisible} />
            </>
          )}
          <CloseButton onClose={onCloseButtonClick} />
        </div>
        <Loading visible={loadingVisible} message={loadingMessage} />
      </>
    )
  }

  return (
    <>
      <Portal>
        <DomecileInterstitial
          show={interstitialVisible}
          hiding={hiding}
          onClose={onClose}
          staticBackdrop
          wrapperClasses={['insurance-quote-interstitial', step, loadingVisible ? 'fullScreenLoading' : '']}
        >
          {renderInterstitialContent()}
        </DomecileInterstitial>
      </Portal>
      <ConfirmCloseInterstitialModal
        open={interstitialVisible && closeConfirmModalVisibility}
        onClose={handleCancelClose}
        onSubmit={handleConfirmClose}
      />
      <ServerErrorModal
        open={interstitialVisible && serverErrorModalVisibility}
        onClose={handleCloseServerErrorModal}
      />
    </>
  )
}

QuoteInterstitial.defaultProps = {
  constants: {},
}

export default QuoteInterstitial
