import React from 'react'
import viaffinityLogo from '/app/assets/images/insurance/logos/viafinity.png'
import chubbLogo from '/app/assets/images/insurance/logos/chubb.png'

export default function ViaffinityDisclosureContent() {
  return (
    <>
      <div className="viaffinity-disclosure-header">
        <div className="viaffinity-disclosure-header__item">© 2024 Viaffinity</div>
        <div className="viaffinity-disclosure-header__item">
          <em>by</em>
          <img src={viaffinityLogo} style={{ width: '56px' }} />
          <em>from</em>
          <img src={chubbLogo} style={{ width: '60px' }} />
        </div>
      </div>
      <div className="viaffinity-disclosure-body">
        Insurance described is offered by Viaffinity, LLC (California license 6011183 d/b/a Viaffinity Insurance
        Services). Insurance is underwritten and provided by Federal Insurance Company or one of its U.S. based Chubb
        underwriting company affiliates. Chubb is the marketing name used to refer to subsidiaries of Chubb Limited
        providing insurance and related services. For a list of these subsidiaries, please visit our website at
        http://www.chubb.com. All products may not be available in all states. This communication contains product
        summaries only. Coverage is subject to the language of the policies as actually issued. Surplus lines insurance
        sold only through licensed surplus lines producers. Chubb, 202 Hall's Mill Road, Whitehouse Station, NJ
        08889-1600
      </div>
      <div className="viaffinity-disclosure-footer">
        <a href="/about/legal" target="_blank" className="link-aqua">
          terms
        </a>
        <a href="/about/privacy_policy" target="_blank" className="link-aqua">
          privacy
        </a>
        <a href="mailto:insurance-support@domecile.com" className="link-aqua">
          support
        </a>
      </div>
    </>
  )
}
