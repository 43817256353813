import React from 'react'
import { Button, Menu, MenuItem, MenuTrigger, Popover } from 'react-aria-components'
import { useDispatch } from 'react-redux'

import StructureEdit from 'components/buildings/edit/StructureEdit'
import { openStructureEditModal } from 'components/buildings/edit/StructureEdit/store/slice'
import StructureArchive from 'components/buildings/edit/StructureArchive'
import { openStructureArchiveModal } from 'components/buildings/edit/StructureArchive/store/slice'

export default function ({ building, buildingStructure, onArchived, archiveVisible }) {
  const dispatch = useDispatch()

  const onAction = (key) => {
    switch (key) {
      case 'edit':
        dispatch(openStructureEditModal({ building, building_structure: buildingStructure }))
        break
      case 'archive':
        dispatch(openStructureArchiveModal({ building, building_structure: buildingStructure }))
        break
      default:
        break
    }
  }

  if (buildingStructure?.default) {
    return null
  }

  return (
    <>
      <MenuTrigger>
        <Button aria-label="Manage Address" className="dome-react-dropdown-trigger">
          manage address <i className="fa-solid fa-chevron-down"></i>
        </Button>
        <Popover className="dome-react-dropdown-popover">
          <Menu onAction={onAction}>
            <MenuItem id="edit">edit</MenuItem>
            {archiveVisible && <MenuItem id="archive">archive</MenuItem>}
          </Menu>
        </Popover>
      </MenuTrigger>
      <StructureEdit />
      <StructureArchive onArchived={onArchived} />
    </>
  )
}
