import React, { useEffect, useState, useRef, useMemo } from 'react'
import eNotaryIcon from '/app/assets/images/e-notary.svg'
import viaffinityLogo from '/app/assets/images/insurance/logos/viafinity.png'
import chubbLogo from '/app/assets/images/insurance/logos/chubb.png'
import starLogo from '/app/assets/images/homepage/star.svg'
import clsx from 'clsx'
import { useWindowSize } from 'lib/hooks'

const TIME_TRANSACTION_ROTATE = 5000

const TABS = [
  {
    id: 'insurance',
    tabName: 'insurance',
    icon: <i className="fa-light fa-umbrella"></i>,
    title: 'insurance, instantly.',
    description:
      "We've partnered with industry leaders to offer insurance for your home. Working with Domecile to obtain insurance ensures that your home is compliant with property regulations.",
    descriptionMobile: 'Ensure your home is compliant with property regulations.',
    buttonName: 'get a quote',
    link: '/about/insurance',
    categories: ['co-op', 'condo', 'renters'],
    categoriesMobile: ['co-op', 'condo', 'renters'],
    logo: true,
  },
  {
    id: 'contractorReport',
    tabName: 'contractor report',
    icon: <i className="fa-light fa-screwdriver-wrench"></i>,
    title: 'contractor report',
    description:
      'We’ve partnered with Contractor Report to modernize the hiring of contractors for construction and alterations. Gain confidence in your contractor with this comprehensive report.',
    descriptionMobile: 'A professional + reliable report to vet your contractor.',
    buttonName: 'learn more',
    link: '/about/contractorreport',
    categories: ['license status', 'references', 'history'],
    categoriesMobile: ['references', 'history'],
  },
  {
    id: 'eNotary',
    tabName: 'e-notary',
    icon: <img src={eNotaryIcon} width={16} className="dome-color-green-gradient" />,
    title: 'e-notary',
    description:
      'Accepted in all 50 states, available 24/7. Simply upload your documents, verify your identity, and you’ll be connected to a notary via an online meeting.',
    descriptionMobile: (
      <span>
        Notarize online. <br /> Any place. Any time.
      </span>
    ),
    buttonName: 'request a notary',
    link: '/about/enotary',
    categories: ['legal', 'convenient', 'eco-friendly'],
    categoriesMobile: ['legal', 'convenient'],
  },
  {
    id: 'concierge',
    tabName: 'concierge',
    icon: <i className="fa-light fa-hand-wave"></i>,
    title: 'concierge',
    description:
      'Trust our experienced pros to make the application process go as smoothly as possible. They will gather information, liaise with deal parties, and ensure error-free transmission.',
    descriptionMobile: 'Trust our experienced pros for an error-free application.',
    buttonName: 'request a concierge',
    link: '/concierge',
    categories: ['co-op', 'condo', 'renters'],
    categoriesMobile: ['co-op', 'condo', 'renters'],
  },
]

const TabLink = ({ tab, isActive, onClick, isMobile }) => {
  return (
    <div
      key={tab.id}
      onClick={onClick}
      className={clsx(
        'what-new-loop-tab-name dome-flex-1 dome-d-flex dome-align-center dome-gap12',
        isActive && 'active-tab-name'
      )}
    >
      <div className="what-new-loop-tab-name-icon dome-d-flex dome-align-center dome-justify-center dome-color-green-gradient">
        <span className="dome-color-green-gradient">{tab.icon}</span>
      </div>
      <div
        className={clsx(
          'what-new-loop-tab-name-text dome-flex-1 dome-p1 dome-color-dark-grey',
          isActive && 'dome-text-w500'
        )}
      >{`${tab.tabName} ${isActive || isMobile ? '>' : ''}`}</div>
    </div>
  )
}

export default function WhatNew() {
  const intervalRef = useRef(null)
  const [width] = useWindowSize()
  const isMobile = width < 821
  const isTablet = width < 990
  const [activeTab, setActiveTab] = useState(0)
  const activeTabData = useMemo(() => TABS[activeTab], [activeTab])

  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      setActiveTab((prevTab) => (prevTab === TABS.length - 1 ? 0 : prevTab + 1))
    }, TIME_TRANSACTION_ROTATE)
  }

  const clearIntervalFunc = () => {
    if (intervalRef.current) clearInterval(intervalRef.current)
  }

  useEffect(() => {
    // Stop the interval when user on tablet and mobile
    if (!isTablet) startInterval()
    return () => clearIntervalFunc()
  }, [isTablet])

  const handleMouseEnter = () => {
    clearIntervalFunc() // Stop the interval when hovered
  }

  const handleMouseLeave = () => {
    startInterval() // Restart the interval when hover is removed
  }

  return (
    <div className="dome-full-height">
      <div className="display-only-desktop dome-full-height">
        <div className="what-new-tab-desktop dome-full-width dome-full-height dome-d-flex dome-justify-center dome-align-center">
          <div className="what-new-title dome-full-height dome-h1 dome-color-green-gradient dome-d-flex dome-flex-column">
            <span>what's</span>
            <span>new</span>
          </div>
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="what-new-loop">
            <div className="what-new-loop-tab dome-full-height dome-d-flex dome-flex-column dome-gap6">
              {TABS.map((tab, index) => (
                <TabLink
                  tab={tab}
                  key={tab.id}
                  isActive={index === activeTab && !isMobile}
                  isMobile={isMobile}
                  onClick={() => setActiveTab(index)}
                />
              ))}
            </div>
            <div className="what-new-loop-content dome-full-height dome-full-width">
              <div className="dome-relative dome-d-flex dome-flex-column dome-gap18 dome-full-height dome-align-start dome-justify-center">
                <div className="dome-flex-1 dome-d-flex dome-align-center">
                  <div className="what-new-loop-content-main dome-d-flex dome-flex-column dome-gap12 dome-align-start">
                    <div className="what-new-loop-content-title dome-h2 dome-text-w400 dome-color-darkest-grey">
                      {activeTabData.title}
                    </div>
                    <div className="what-new-loop-content-description dome-p1 dome-color-dark-grey">
                      {activeTabData.description}
                    </div>
                    <div className="dome-d-flex">
                      {activeTabData.categories.map((category) => (
                        <span
                          key={category}
                          className="what-new-loop-content-category-item dome-p1 dome-color-med-grey"
                        >
                          {category}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="what-new-loop-footer">
                  <a
                    href={activeTabData.link}
                    className="what-new-loop-footer-button dome-link dome-is-button dome-link-dark-green"
                  >
                    {activeTabData.buttonName}
                  </a>
                  {activeTabData.logo && (
                    <div className="what-new-loop-logo dome-d-flex dome-align-center dome-gap6">
                      <span className="what-new-loop-logo-text dome-color-darkest-grey">insurance provided by</span>
                      <div className="what-new-loop-logo-body dome-d-flex dome-align-center dome-gap6">
                        <img class="logo chubb" src={chubbLogo} alt="chubb logo" width={70} />
                        <img class="logo viaffinity" src={viaffinityLogo} alt="viaffinity logo" width={67} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="display-only-mobile">
        <div className="what-new-tab-mobile dome-d-flex">
          <div className="what-new-tab-mobile-left dome-d-flex dome-flex-column dome-gap24">
            <div className="dome-color-green-gradient dome-d-flex dome-align-center dome-gap12">
              <img className="what-new-tab-mobile-left-logo" src={starLogo} width={15} height={15} alt="srar logo" />
              <span className="what-new-tab-mobile-left-title dome-h2">what's new</span>
            </div>
            <div className="what-new-tab-mobile-tab dome-flex-1 dome-d-flex dome-flex-column dome-gap6">
              {TABS.map((tab, index) => (
                <TabLink
                  tab={tab}
                  key={tab.id}
                  isMobile={isMobile}
                  isActive={index === activeTab && !isMobile}
                  onClick={() => (isMobile ? (window.location.href = tab.link) : setActiveTab(index))}
                />
              ))}
            </div>
          </div>
          <div className="what-new-tab-mobile-right dome-flex-1 dome-d-flex dome-align-start dome-justify-center">
            <div className="what-new-tab-mobile-right-content dome-d-flex dome-flex-column dome-gap12">
              <div className="dome-h4 dome-color-darkest-grey">{activeTabData.title}</div>
              <div className="dome-p1 dome-color-dark-grey">{activeTabData.descriptionMobile}</div>
              <div className="dome-d-flex">
                {activeTabData.categoriesMobile.map((category) => (
                  <span key={category} className="what-new-loop-content-category-item dome-p2 dome-color-med-grey">
                    {category}
                  </span>
                ))}
              </div>
              <a href={activeTabData.link} className="dome-link dome-is-button dome-link-dark-green">
                {activeTabData.buttonName}
              </a>
              {activeTabData.logo && (
                <div className='dome-d-flex dome-gap3 dome-flex-column'>
                  <span className="what-new-loop-logo-text dome-color-darkest-grey">insurance provided by</span>
                  <div className="dome-d-flex dome-gap12 dome-align-center">
                    <img class="logo chubb" src={chubbLogo} alt="chubb logo" width={86} />
                    <img class="logo viaffinity" src={viaffinityLogo} alt="viaffinity logo" width={83} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
