import React, { useRef, useState } from 'react'
import eNotaryIcon from '/app/assets/images/e-notary.svg'
import InstantQuoteLookupOverlay from 'components/insurance/get_quote/instant_quote_lookup/overlay'
import Steps_for_modals from 'components/e_notary/steps_for_modals'
import FormContractorReportModal from 'components/contractor_report/modal'
import AllStepsModal from 'components/concierge/all_steps_modal'

const RESOURCES = [
  {
    title: 'insurance',
    description: 'Get an instant quote.',
    icon: <i className="fa-xl fa-thin fa-umbrella"></i>,
    buttonText: 'get quote',
    component: ({ open, onCloseModal }) => <InstantQuoteLookupOverlay open={open} onClose={onCloseModal} />,
  },
  {
    title: 'contractor report',
    description: 'Vet your contractor.',
    icon: <i className="fa-xl fa-thin fa-screwdriver-wrench"></i>,
    buttonText: 'request report',
    component: ({ open, onCloseModal }) => <FormContractorReportModal open={open} onClose={onCloseModal} />,
  },
  {
    title: 'concierge',
    description: 'White-glove service.',
    icon: <i className="fa-xl fa-thin fa-hand-wave"></i>,
    buttonText: 'request quote',
    component: ({ open, onCloseModal, concierge_package_types, ...props }) => (
      <AllStepsModal step={open ? 1 : 0} onClose={onCloseModal} package_types={concierge_package_types} {...props} />
    ),
  },
  {
    title: 'e-notary',
    description: 'Any place, any time.',
    icon: <img src={eNotaryIcon} width={20} className="dome-color-green-gradient" />,
    buttonText: 'set a time',
    component: ({ open, onCloseModal, enotary_price }) => (
      <Steps_for_modals step={open ? 1 : 0} onClose={onCloseModal} enotary_price={enotary_price} />
    ),
  },
]

const ResourceItem = ({ item, ...modalProps }) => {
  const [openModal, setOpenModal] = useState(false)

  const onOpenModal = () => setOpenModal(true)

  const onCloseModal = () => setOpenModal(false)

  return (
    <>
      <div
        onClick={onOpenModal}
        className={`additional-resource-group-item ${openModal ? 'active' : ''} dome-d-flex dome-flex-column dome-justify-between dome-gap12 `}
      >
        <div className="additional-resource-group-item-body dome-d-flex dome-gap6">
          <div className="additional-resource-group-item-icon dome-d-flex dome-align-center dome-justify-center">
            <span>{item.icon}</span>
          </div>
          <div className="dome-d-flex dome-flex-column">
            <span className="dome-p2 dome-color-dark-grey dome-text-w500">{item.title}</span>
            <span className="dome-p3 dome-color-med-grey">{item.description}</span>
          </div>
        </div>
        <div className="additional-resource-group-footer dome-d-flex dome-justify-end">
          <button className="additional-resource-group-item-button dome-btn dome-btn-link dome-btn-link-aqua dome-btn-link-chevron-right">
            {item.buttonText}
          </button>
        </div>
      </div>
      {item.component({
        open: openModal,
        onCloseModal: onCloseModal,
        ...modalProps,
      })}
    </>
  )
}

export default function AdditionalResource(props) {
  return (
    <div className="additional-resource-container dome-d-flex dome-flex-column dome-gap18">
      <div className="additional-resource-group">
        {RESOURCES.map((item) => (
          <ResourceItem key={item.title} item={item} {...props} />
        ))}
      </div>
      <span className="dome-p3 dome-text-style-italic dome-color-med-grey">
        Insurance offered for select properties only. Domecile Services are administered exclusively by Domecile. All
        services are separate from those provided by Property Management Companies to their clients. All Domecile
        services are provided with full knowledge and cooperation with Property Management, but without Property
        Management supervision. Any fees associated with services offered are Domecile fees.
      </span>
    </div>
  )
}
