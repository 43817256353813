import React, { useEffect } from 'react'
import { useWatchableRef } from 'lib/hooks'
import { Portal } from 'components/utility/portal'

export default function FraudDisclosureModal({ open, onClose }) {
  const modalRef = useWatchableRef(null)

  useEffect(() => {
    if (!modalRef.current) return

    const handleClose = (_e) => onClose()
    const localRefValue = modalRef.current

    $(localRefValue).on('hidden.bs.modal', handleClose)

    return () => $(localRefValue).off('hidden.bs.modal', handleClose)
  }, [onClose, modalRef.version])

  useEffect(() => {
    if (!modalRef.current) return

    if (open) {
      $(modalRef.current).modal('show')
    } else {
      $(modalRef.current).modal('hide')
    }
  }, [open, modalRef.version])

  return (
    <Portal>
      <div ref={modalRef.ref} className="modal fade fraud-disclosure-modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content new-brp-modal">
            <div className="modal-header">
              <div className="dome-h2 dome-color-dark-green">fraud disclosure</div>
            </div>
            <div className="modal-body">
              <div className="dome-p2">
                Any person who knowingly and with intent to defraud any insurance company or other person files an
                application for insurance or statement of claim containing any materially false information, or conceals
                for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent
                insurance act, which is a crime, and shall also be subject to a civil penalty not to exceed five
                thousand dollars and the stated value of the claim for each such violation.
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="dome-btn dome-btn-link dome-btn-link-cancel pull-left dome-color-med-grey"
                data-dismiss="modal"
                onClick={onClose}
              >
                close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  )
}
