import React from 'react'
import ContractorReportAdIcon from '/app/assets/images/icons/Frame.svg'

// ConciergeAd is present in all specified package types and excluded from ContractorReportAd package types.
const PACKAGE_TYPES_FOR_CONTRACTOR_REPORT_AD = [
  'decorative_alteration',
  'minor_alteration',
  'major_alteration',
  'alteration',
]

const ContractorReportAd = () => {
  return (
    <>
      <div className="display-only-desktop">
        <div className="contractor-report-ad dome-d-flex dome-align-center dome-gap6">
          <img src={ContractorReportAdIcon} />
          <div className="dome-d-flex dome-align-center dome-gap18">
            <span className="dome-color-green-gradient dome-text-w500">contractor report</span>
            <div className="dome-d-flex dome-align-center dome-gap6">
              <span className="dome-text-w300 dome-p3">Vet your contractor.</span>
              <a
                href="/about/contractorreport"
                className="dome-text-w500 dome-link dome-link-aqua dome-link-chevron-right"
              >
                learn more
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="display-only-mobile">
        <div className="dome-d-flex dome-align-center contractor-report-ad contractor-report-ad-mobile">
          <img src={ContractorReportAdIcon} />
          <span className="dome-color-green-gradient dome-text-w500">vet your contractor</span>
          <a href="/about/contractorreport">
            <i className="fa-regular fa-arrow-right-long dome-color-green-gradient"></i>
          </a>
        </div>
      </div>
    </>
  )
}

const ConciergeAd = () => {
  return (
    <div className="concierge-ad dome-d-flex dome-align-start dome-gap6">
      <i className="concierge-ad-icon fa-light fa-sparkle dome-color-aqua"></i>
      <div>
        <div className="dome-d-flex dome-justify-between">
          <span className="dome-color-aqua dome-text-w500">concierge</span>
          <a href="/concierge" className="dome-text-w500 dome-link dome-link-aqua dome-link-chevron-right">
            learn more
          </a>
        </div>
        <span className="dome-text-w300 dome-p3">White-glove application service. </span>
      </div>
    </div>
  )
}

export default function AdvertisementButton({ packageType }) {
  if (PACKAGE_TYPES_FOR_CONTRACTOR_REPORT_AD.includes(packageType)) return <ContractorReportAd />

  return <ConciergeAd />
}
