import clsx from 'clsx'
import React from 'react'

const ResourceSectionWrapper = ({ className, onBack, onClick, children }) => {
  return (
    <div className="resource-section-wrapper">
      <div className={clsx('dome-d-flex dome-flex-column dome-align-start dome-full-height', className)}>
        {children}
      </div>
    </div>
  )
}

const Header = ({ icon, label }) => {
  return (
    <div className="dome-d-flex dome-align-center dome-gap6">
      <div className="resource-section-icon dome-d-flex dome-align-center dome-justify-center">
        <span className="dome-color-dark-grey">{icon}</span>
      </div>
      <span className="dome-p1 dome-color-dark-grey">{label}</span>
    </div>
  )
}

const Title = ({ title }) => {
  return (
    <div style={{ marginBottom: '12px' }} className="dome-p3 dome-color-dark-grey dome-text-left">
      {title}
    </div>
  )
}

const Footer = ({ hasButton = true, children, btnName, btnClassName, onBack, onClick }) => {
  return (
    <div className="dome-full-width dome-d-flex dome-justify-between">
      {onBack && (
        <button
          type="button"
          onClick={onBack}
          className="dome-btn dome-btn-link dome-btn-link-med-grey dome-btn-link-chevron-left"
        >
          back
        </button>
      )}
      {hasButton ? (
        <button onClick={onClick} type="button" className={btnClassName}>
          {btnName}
        </button>
      ) : (
        children
      )}
    </div>
  )
}

ResourceSectionWrapper.Header = Header
ResourceSectionWrapper.Tilte = Title
ResourceSectionWrapper.Footer = Footer

export default ResourceSectionWrapper
