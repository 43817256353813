import React, { useState, useEffect, useCallback } from 'react'
import { transform } from 'lodash'

import { scrollTopAt, distanceFromTop } from 'lib/utilities/dom'

const HUGE = 999999999

export function addIdsToValues(hash) {
  const iterator = (result, value, key) => result[key] = {...value, ...{ id: key }}
  return transform(hash, iterator, {})
}

const APPEND_TO_KEY_DEFAULTS = {
  unique: true
}
export const appendToKey = (obj, key, item, options = {}) => {
  options = { ... APPEND_TO_KEY_DEFAULTS, ... options }  

  if (!obj[key])
    return obj[key] = [ item ]

  if (options.unique)
    if (obj[key].includes(item))
      return
  
  obj[key].push(item)
}

export function sortByPosition(posA, posB) {
  if (posA.page != posB.page)
    return (parseInt(posA.page) || HUGE) - (parseInt(posB.page) || HUGE)

  if (posA.top != posB.top)
    return (parseInt(posA.top) || HUGE) - (parseInt(posB.top) || HUGE)

  if (posA.left != posB.left)
    return (parseInt(posA.left) || HUGE) - (parseInt(posB.left) || HUGE)

  return 0
}

export function useHeaderTop() {
  const [top, setTop] = useState(0)

  const tracker = useCallback(() => {
    const header = document.getElementsByTagName('header')[0]
    const headerRect = header.getBoundingClientRect()
    setTop(headerRect.height)
  })
  
  useEffect(() => {
    tracker()
    window.addEventListener("resize", tracker)
    return () => window.removeEventListener("resize", tracker)
  }, [tracker])

  return top
}

export function useScrollingTop(parentRef, { ignoreHeader = true} = {}) {
  const [top, setTop] = useState(0)
  const headerTop = useHeaderTop()
  
  const resetTop = useCallback(() => {
    const currentScroll = scrollTopAt(parentRef.current)
    const topDistance = distanceFromTop(parentRef.current)

    setTop(Math.max(topDistance - currentScroll, ignoreHeader ? 0 : headerTop))
  }, [parentRef.current])

  useEffect(() => {
    resetTop()
    window.addEventListener('scroll', resetTop)
    return () => window.removeEventListener('scroll', resetTop)
  }, [resetTop])

  return top
}


const STEP_MATCH = /([^\[]+)\[(\d+)\]/
function translateStep(step) {
  if (!step)
    return {}

  const match = String(step).match(STEP_MATCH)
  return match ? { key: match[1], index: parseInt(match[2])} : { key: step }
}

function itemAtStep(hash, step) {
  const {key, index} = translateStep(step)

  hash = hash[key]
  if (!index)
    return hash

  return typeof hash == "object" ? hash[index] : null
}

export function itemAtPath(hash, path) {
  const steps = path.split(".")
  for (let step of steps)
    hash = itemAtStep(hash, step)

  return hash
}

// export function createAtPath(hash, path, value) {

// }