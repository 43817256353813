import { useCallback, useMemo, useRef, useState, useEffect, useLayoutEffect } from "react";

export function useWatchableRef(initialValue = null) {
  const ref = useRef(initialValue)
  const [version, setRefVersion] = useState(null)

  const handleRef = useCallback((node) => {
    ref.current = node
    setRefVersion(Math.random())
  }, [])

  return useMemo(
    () => ({
      ref: handleRef,
      current: ref.current,
      version,
    }),
    [handleRef, version]
  )
}

export const useModal = ({ open, onClose, onOpen }) => {
  const modalRef = useWatchableRef(null)

  useEffect(() => {
    if (!modalRef.current) return

    const localRefValue = modalRef.current
    const handleClose = (e) => (typeof onClose === 'function' ? onClose(e) : null)

    $(localRefValue).on('hidden.bs.modal', handleClose)
    return () => $(localRefValue).off('hidden.bs.modal', handleClose)
  }, [onClose, modalRef.version])

  useEffect(() => {
    if (!modalRef.current) return

    if (open) {
      $(modalRef.current).modal('show')

      if (typeof onOpen === 'function') onOpen()
    } else {
      $(modalRef.current).modal('hide')
    }
  }, [open, modalRef.version, onOpen])

  return useMemo(
    () => ({
      modalRef,
    }),
    []
  )
}

export function useWindowSize() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}
