import { useMemo } from 'react'
import { isEmpty } from 'lodash-es'

export const useGetStartingQuote = (building, insuranceType) => {
  const startingQuotes = useMemo(() => {
    if (isEmpty(building?.insurance_building_settings)) return {}

    const settings = {}

    building.insurance_building_settings.forEach((setting) => {
      if (setting['starting_quote_relevant?']) {
        settings[setting.unit_type] = setting.serialized_starting_quote
      }
    })

    return settings
  }, [building?.insurance_building_settings])

  const startingQuote = useMemo(() => {
    return startingQuotes[insuranceType]
  }, [startingQuotes, insuranceType])

  return startingQuote
}
