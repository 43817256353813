import React from 'react'

import Button from './Button'
import EmailField from './EmailField'

const ForgotPassword = (props) => {
  const handleClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    props.updateStep(2)
  }

  return (
    <>
      <EmailField
        ref={props.inputRef}
        register={props.register}
        currentStep={props.currentStep}
        reset={props.reset} />
      <div className="recovery-buttons">
        <button onClick={handleClick} className="btn-link back-link">
          back
        </button>
        <Button type="submit" className="btn-base btn-dark-green" disabled={!props.isValidEmail}>
          recover
        </Button>
      </div>
    </>
  )
}

export default ForgotPassword
