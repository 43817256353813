import React from 'react'

import { pick } from 'lodash'
import Form from "./Form"
import SSOCheck from "./SSOCheck"
import StandardLogin from "./StandardLogin"
import ForgotPassword from './ForgotPassword'

const Forms = (props) => {
  const {
    currentStep,
    checkSSO,
    postLogin,
    sendRecovery,
    handleSubmit,
    isSubmitSuccessful,
    updatePasswordFieldState,
  } = props

  return (
    <>
      {currentStep === 1 && (
        <Form submitFn={checkSSO} handleSubmit={handleSubmit}>
          <SSOCheck { ...pick(props, ['inputRef', 'register', 'currentStep', 'isLoading', 'errors', 'reset', 'isDirty', 'isValidEmail']) } />
        </Form>
      )}
      {currentStep === 2 && (
        <Form submitFn={postLogin} action='/sessions_create_with_json' method='POST' handleSubmit={handleSubmit}>
          <StandardLogin 
            { ...pick(props, ['inputRef', 'register', 'currentStep', 'isLoading', 'inputRefPassword', 'hasInput', 'updateStep', 'errors']) }
            isLoading={isSubmitSuccessful}
            onKeyUp={updatePasswordFieldState}
          />
        </Form>
      )}
      {currentStep === 3 && (
        <Form submitFn={sendRecovery} action='/users/password' method='POST' handleSubmit={handleSubmit}>
          <ForgotPassword { ...pick(props, ['inputRef', 'register', 'currentStep', 'reset', 'isValidEmail', 'updateStep']) } />
        </Form>
      )}
    </>
  )
}

export default Forms