import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import clsx from 'clsx'
import { add, addDays, addYears } from 'date-fns'
import { format, toZonedTime } from 'date-fns-tz'
import CurrencyInput from 'react-currency-input-field'
import { ceil } from 'lodash-es'

import Autosuggest from 'components/utility/autosuggest'

import ViaffinityDisclosureContent from '../../ViaffinityDisclosureContent'
import InsuranceTooltip from '../../InsuranceTooltip'
import { formFields, INSURANCE_QUOTE_STEPS, selectInsuranceQuoteInterstitialState } from 'components/insurance/get_quote/slice/slice'
import { BIG_NUMBER, formattedMoney, formattedNumber } from 'components/insurance/get_quote/utils'
import {
  HomeownersDwellingAmountTooltipContent,
  RentersDwellingAmountTooltipContent,
} from './dwellingAmountTooltipContent'

const MINIMUM_CONTENT_AMOUNT = 50000
const MINIMUM_DWELLING_AMOUNT = {
  homeowners: 250000,
  renters: 2500,
}
const INSURANCE_OFFSET = {
  homeowners: 45,
  renters: 30,
}

const BuildingStructureSelect = React.forwardRef(
  ({ building, building_structure_id, name, defaultValue, onChange, onBlur, ...restProps }, forwardedRef) => {
    const [value, setValue] = useState(defaultValue)

    const internalRef = useRef(null)
    const setRef = useCallback(
      (dom) => {
        if (typeof forwardedRef == 'function') {
          forwardedRef(dom)
        }
        internalRef.current = dom
      },
      [forwardedRef]
    )

    const building_structures = useMemo(() => building?.building_structures || [], [building])

    const handleChange = useCallback(
      (evt) => {
        setValue(evt.target.value)
      },
      [onChange, setValue]
    )

    const handleBlur = useCallback(
      (evt) => {
        onBlur ? onBlur(evt) : null
      },
      [onBlur]
    )

    useEffect(() => {
      if (building_structures.length == 1) setValue(building_structures[0].id)
    }, [building_structures, setValue])

    useEffect(() => {
      const event = new Event('change')
      Object.defineProperty(event, 'target', { value: internalRef.current, enumerable: true })
      onChange(event)
    }, [onChange, value])

    const options = useMemo(
      () =>
        building_structures.map((building_structure) => (
          <option key={building_structure.id} value={building_structure.id}>
            {building_structure.default_additional_address.address}
          </option>
        )),
      [building_structures]
    )

    if (building_structures.length == 0) return null

    if (building_structure_id || building_structures.length == 1) {
      const building_structure = building_structure_id
        ? building_structures.find((building_structure) => building_structure.id === building_structure_id)
        : building_structures[0]

      const inputProps = {
        ...restProps,
        name,
        ref: setRef,
        type: 'hidden',
        value: building_structure?.id,
      }

      return (
        <>
          <div className="static-value">{building_structure?.default_additional_address.address}</div>
          <input {...inputProps} />
        </>
      )
    }

    const selectProps = {
      ...restProps,
      ref: setRef,
      defaultValue,
      name,
      onChange: handleChange,
      onBlur: handleBlur,
    }

    return (
      <select id="building_structure_id" {...selectProps}>
        <option disabled value="">
          select an address
        </option>
        {options}
      </select>
    )
  }
)

const ErrorsFromServer = React.memo(({ error }) => {
  return <div className="p3 dome-color-red-alert">{error}</div>
})

const selectRequiredValidation = (message) => (value) => {
  return !value ? message : true
}

const validateMultipleOfThousand = (message) => (value) => {
  return value % 1000 !== 0 ? message : true
}

const validateMultipleOfHundred = (message) => (value) => {
  return value % 100 !== 0 ? message : true
}

const useHandleUnitNumberChanged = (buildingUnitOptions, setValue, trigger, buildingUnitWatch) => {
  const prevBuildingUnit = useRef(buildingUnitWatch)

  return useCallback(
    (e) => {
      const newUnitNumber = e.target.value

      if (newUnitNumber && newUnitNumber !== prevBuildingUnit.current) {
        const buildingUnit = buildingUnitOptions.find((building_unit) => building_unit.unit_number === newUnitNumber)

        setValue('square_footage', buildingUnit?.square_footage)
        if (buildingUnit?.square_footage) trigger('square_footage')
      }

      prevBuildingUnit.current = newUnitNumber
    },
    [buildingUnitOptions, setValue, trigger, buildingUnitWatch]
  )
}

export default function PolicyForm({
  canGoBack,
  onBack,
  onNext,
  formData,
  building,
  building_structure_id,
  insuranceType,
  formOptions,
}) {
  const today = new Date()
  const {
    occupancyTypes,
    personalLiabilityLimits,
    deductibleAmounts,
    minimumContentAmount: minimumContentAmountSetting,
    minimumDwellingAmount: minimumDwellingAmountSetting,
    required,
    defaultValues: formDefaultValues,
  } = formOptions

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    watch,
    control,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      ...formDefaultValues,
      start_at: format(addDays(today, INSURANCE_OFFSET[insuranceType]), 'yyyy-MM-dd'),
      quote_expires_at: format(add(today, { days: INSURANCE_OFFSET[insuranceType], years: 1 }), 'yyyy-MM-dd'),
      ...formData,
    },
    mode: 'onBlur',
  })

  const { errorDescription, formErrors } = useSelector(selectInsuranceQuoteInterstitialState)

  useEffect(() => {
    const policyFormFields = formFields[INSURANCE_QUOTE_STEPS.policy_page]
    Object.keys(formErrors).filter(key => policyFormFields.includes(key)).forEach(k => {
      setError(k, { type: 'custom', message: typeof(formErrors[k]) === "Array" ? formErrors[k].join(', ') : formErrors[k] })
    })
  }, [formErrors])
  

  const minimumContentAmount = useMemo(() => {
    if (MINIMUM_CONTENT_AMOUNT > minimumContentAmountSetting) return MINIMUM_CONTENT_AMOUNT
    if (minimumContentAmountSetting % 1000 === 0) return minimumContentAmountSetting

    return minimumContentAmountSetting - (minimumContentAmountSetting % 1000) + 1000
  }, [minimumContentAmountSetting])

  const minimumDwellingAmount = useMemo(() => {
    if (MINIMUM_DWELLING_AMOUNT[insuranceType] > minimumDwellingAmountSetting)
      return MINIMUM_DWELLING_AMOUNT[insuranceType]

    return ceil(minimumDwellingAmountSetting, -2)
  }, [minimumDwellingAmountSetting, insuranceType])

  const goBack = () => {
    const formDataToBeSaved = { ...formData, ...getValues() }
    onBack(formDataToBeSaved)
  }

  const onSubmit = (data) => {
    const formDataToBeSaved = { ...data, ...getValues(), modifiedAt: (new Date()).toISOString() }
    onNext(formDataToBeSaved)
  }

  const buildingStructureIdWatch = watch('building_structure_id')
  const buildingUnitWatch = watch('building_unit')
  const squareFootageWatch = watch('square_footage')
  const contentCoverageWatch = watch('content_coverage')

  const buildingStructure = useMemo(
    () =>
      building?.building_structures.find(
        (building_structure) => building_structure.id === parseInt(buildingStructureIdWatch)
      ),
    [buildingStructureIdWatch]
  )

  const buildingUnitOptions = useMemo(() => buildingStructure?.building_units || [], [buildingStructure])

  const handleUnitNumberChanged = useHandleUnitNumberChanged(buildingUnitOptions, setValue, trigger, buildingUnitWatch)

  const dwellingAmountTooltipContent = useMemo(() => {
    switch (insuranceType) {
      case 'homeowners':
        return (
          <HomeownersDwellingAmountTooltipContent
            squareFootageWatch={squareFootageWatch}
            minimumDwellingAmount={minimumDwellingAmount}
          />
        )
      case 'renters':
        return (
          <RentersDwellingAmountTooltipContent
            contentCoverageWatch={contentCoverageWatch}
            minimumDwellingAmount={minimumDwellingAmount}
          />
        )
      default:
        return 'The amount will be based on your insurance type, square footage and content amounts'
    }
  }, [insuranceType, squareFootageWatch, contentCoverageWatch, minimumDwellingAmount])

  return (
    <div className="policy-content-container">
      <form onSubmit={handleSubmit(onSubmit)} className="policy-form">
        <div className="policy-form__section">
          <div className="policy-form__subsection">
            <div className="dome-h4 dome-color-dark-grey">your unit</div>
            <div className="policy-form__form-groups">
              <div className="policy-form__form-group input-building-address">
                <label htmlFor="building_structure_id">building address*</label>
                <BuildingStructureSelect
                  id="building_structure_id"
                  defaultValue=""
                  building={building}
                  building_structure_id={building_structure_id}
                  {...register('building_structure_id', {
                    validate: {
                      required: selectRequiredValidation('required'),
                    },
                  })}
                />
                {errors?.building_structure_id && (
                  <div className="dome-p3 dome-color-red-alert">{errors.building_structure_id.message}</div>
                )}
              </div>
              <div className="policy-form__form-group input-unit">
                <label htmlFor="building_unit">unit*</label>
                <Autosuggest
                  id="building_unit"
                  defaultValue=""
                  value={buildingUnitWatch}
                  options={buildingUnitOptions.map((building_unit) => building_unit.unit_number)}
                  {...register('building_unit', {
                    required: 'required',
                    onBlur: handleUnitNumberChanged,
                  })}
                />
                {errors?.building_unit && (
                  <div className="dome-p3 dome-color-red-alert">{errors.building_unit.message}</div>
                )}
              </div>
            </div>
            <div className="policy-form__form-groups">
              <div className="policy-form__form-group input-sq-footage">
                <label htmlFor="square_footage">sq. footage*</label>
                <Controller
                  control={control}
                  name="square_footage"
                  rules={{
                    required: 'required',
                    max: { value: BIG_NUMBER, message: `square footage maximum is ${formattedNumber(BIG_NUMBER)}` },
                  }}
                  render={({ field: { onChange, onBlur, name, value } }) => {
                    return (
                      <CurrencyInput
                        allowDecimals={false}
                        name={name}
                        id={name}
                        value={value}
                        prefix=""
                        onBlur={onBlur}
                        onValueChange={onChange}
                        allowNegativeValue={false}
                      />
                    )
                  }}
                />
                {errors?.square_footage && (
                  <div className="dome-p3 dome-color-red-alert">{errors.square_footage.message}</div>
                )}
              </div>
              <div className="policy-form__form-group input-occupancy">
                <label htmlFor="occupancy">
                  occupancy*
                  <i
                    className="fa-light fa-circle-question dome-color-dark-green"
                    style={{ marginLeft: '6px' }}
                    data-tip
                    data-for="occupancy_tooltip"
                  />
                </label>
                <InsuranceTooltip
                  id="occupancy_tooltip"
                  header="What does occupancy mean?"
                  content="Occupancy (resident type) is the proportion of time per year that you reside at this address."
                />
                <select
                  {...register('occupancy', {
                    validate: {
                      required: selectRequiredValidation('required'),
                    },
                  })}
                  id="occupancy"
                  defaultValue=""
                >
                  <option disabled value="">
                    select occupancy type
                  </option>
                  {occupancyTypes.map((occupancyType) => (
                    <option value={occupancyType} key={occupancyType}>
                      {occupancyType}
                    </option>
                  ))}
                </select>
                {errors?.occupancy && <div className="dome-p3 dome-color-red-alert">{errors.occupancy.message}</div>}
              </div>
            </div>
          </div>
          <div className="policy-form__subsection">
            <div className="dome-h4 dome-color-dark-grey">coverage</div>
            <PolicyCoverageDescription insuranceType={insuranceType} required={required} />
            <div className="policy-form__form-groups">
              <div className="policy-form__form-group input-effective-date">
                <label htmlFor="start_at">
                  policy effective date*
                  <i
                    className="fa-light fa-circle-question dome-color-dark-green"
                    style={{ marginLeft: '6px' }}
                    data-tip
                    data-for="start_at_tooltip"
                  />
                </label>
                <InsuranceTooltip
                  id="start_at_tooltip"
                  header="What is the policy effective date?"
                  content="This is the date you plan to take ownership of your new home or the day your prior policy expires. It’s okay to use your best estimated date of occupancy at this time and update it as you finalize your policy."
                />
                <input
                  type="date"
                  {...register('start_at', {
                    required: 'required',
                    onChange: (e) => {
                      const startDate = new Date(e.currentTarget.value + 'T00:00:00.000Z')
                      const endDate = addYears(startDate, 1)
                      setValue('quote_expires_at', format(toZonedTime(endDate, 'etc/UTC'), 'yyyy-MM-dd'))
                    },
                  })}
                  id="start_at"
                  min={add(new Date(), { days: 1 }).toISOString().split('T')[0]}
                  max={add(new Date(), { days: 60 }).toISOString().split('T')[0]}
                />
                {errors?.start_at && <div className="dome-p3 dome-color-red-alert">{errors.start_at.message}</div>}
              </div>
              <div className="policy-form__form-group input-expiration-date">
                <label htmlFor="quote_expires_at">
                  policy expiration date*
                  <i
                    className="fa-light fa-circle-question dome-color-dark-green"
                    style={{ marginLeft: '6px' }}
                    data-tip
                    data-for="quote_expires_at_tooltip"
                  />
                </label>
                <InsuranceTooltip
                  id="quote_expires_at_tooltip"
                  header="Why can’t I change the expiration date?"
                  content="All policies have a one-year duration."
                />
                <input type="date" {...register('quote_expires_at')} id="quote_expires_at" disabled />
                {errors?.quote_expires_at && (
                  <div className="dome-p3 dome-color-red-alert">{errors.quote_expires_at.message}</div>
                )}
              </div>
            </div>
            <div className="policy-form__form-groups">
              <div className="policy-form__form-group input-liability-limit">
                <label htmlFor="personal_liability">
                  personal liability limit*
                  <i
                    className="fa-light fa-circle-question dome-color-dark-green"
                    style={{ marginLeft: '6px' }}
                    data-tip
                    data-for="personal_liability_tooltip"
                  />
                </label>
                <InsuranceTooltip
                  id="personal_liability_tooltip"
                  header="What is your personal liability?"
                  content="If someone gets hurt on your property and decides to take legal action, this is the maximum amount paid in a claim to cover damages."
                />
                <select
                  {...register('personal_liability', {
                    validate: {
                      required: selectRequiredValidation('required'),
                    },
                  })}
                  id="personal_liability"
                  defaultValue=""
                >
                  <option disabled value="">
                    select personal liability
                  </option>
                  {personalLiabilityLimits.map((liabilityLimit) => (
                    <option key={liabilityLimit} value={liabilityLimit}>
                      {formattedMoney(liabilityLimit)}
                    </option>
                  ))}
                </select>
                {errors?.personal_liability && (
                  <div className="dome-p3 dome-color-red-alert">{errors.personal_liability.message}</div>
                )}
              </div>
              <div className="policy-form__form-group input-deductible-amount">
                <label htmlFor="deductible_amount">
                  base deductible amount*
                  <i
                    className="fa-light fa-circle-question dome-color-dark-green"
                    style={{ marginLeft: '6px' }}
                    data-tip
                    data-for="deductible_amount_tooltip"
                  />
                </label>
                <InsuranceTooltip
                  id="deductible_amount_tooltip"
                  header="What is this?"
                  content={`This is the amount that will be deducted from a claim issued over that amount. For example, the insurer pays $10,000 for a claim and your deductible is $1,000, you will receive $9,000. `}
                />
                <select
                  {...register('deductible_amount', {
                    validate: {
                      required: selectRequiredValidation('required'),
                    },
                  })}
                  id="deductible_amount"
                  defaultValue=""
                >
                  <option disabled value="">
                    select base deductible amount
                  </option>
                  {deductibleAmounts.map((deductibleAmount) => (
                    <option key={deductibleAmount} value={deductibleAmount}>
                      {formattedMoney(deductibleAmount)}
                    </option>
                  ))}
                </select>
                {errors?.deductible_amount && (
                  <div className="dome-p3 dome-color-red-alert">{errors.deductible_amount.message}</div>
                )}
              </div>
            </div>
            <div className="policy-form__form-groups">
              <div className="policy-form__form-group input-personal-contents-amount">
                <label htmlFor="content_coverage">
                  personal contents amount*
                  <i
                    className="fa-light fa-circle-question dome-color-dark-green"
                    style={{ marginLeft: '6px' }}
                    data-tip
                    data-for="content_coverage_tooltip"
                  />
                </label>
                <InsuranceTooltip
                  id="content_coverage_tooltip"
                  header="What are your personal contents?"
                  content={`Personal contents protects against loss or damage of possessions in your condo that you would take if you moved, such as valuables, furniture, electronics, clothing, personal belongings, and household goods. Add up the replacement costs of your belongings to get a total value. This total is an estimate of how much coverage you might need.`}
                  footer="Chubb offers replacement cost rather than actual cash value, which is the depreciated value of a personal property item. Replacement cost is better."
                />
                <Controller
                  control={control}
                  name="content_coverage"
                  rules={{
                    required: 'required',
                    min: {
                      value: minimumContentAmount,
                      message: `Contents amount minimum is ${formattedMoney(minimumContentAmount)}`,
                    },
                    max: { value: BIG_NUMBER, message: `Contents amount maximum is ${formattedMoney(BIG_NUMBER)}` },
                    validate: {
                      validateMultipleOfThousand: validateMultipleOfThousand(
                        'Contents amount must be a multiple of $1000'
                      ),
                    },
                  }}
                  render={({ field: { onChange, onBlur, name, value } }) => {
                    return (
                      <CurrencyInput
                        name={name}
                        id={name}
                        defaultValue={value}
                        decimalsLimit={2}
                        prefix="$"
                        onBlur={onBlur}
                        onValueChange={onChange}
                        allowNegativeValue={false}
                      />
                    )
                  }}
                />
                {errors?.content_coverage && (
                  <div className="dome-p3 dome-color-red-alert">{errors.content_coverage.message}</div>
                )}
              </div>
              <div className="policy-form__form-group input-alterations-amount">
                <label htmlFor="additions_alterations_amount">
                  dwelling amount*
                  <i
                    className="fa-light fa-circle-question dome-color-dark-green"
                    style={{ marginLeft: '6px' }}
                    data-tip
                    data-for="additions_alterations_amount_tooltip"
                  />
                </label>
                <InsuranceTooltip
                  id="additions_alterations_amount_tooltip"
                  header="How do I determine my dwelling amount?"
                  content={dwellingAmountTooltipContent}
                />
                <Controller
                  control={control}
                  name="additions_alterations_amount"
                  rules={{
                    required: 'required',
                    min: {
                      value: minimumDwellingAmount,
                      message: `Dwelling amount minimum is ${formattedMoney(minimumDwellingAmount)}`,
                    },
                    max: {
                      value: BIG_NUMBER,
                      message: `Dwelling amount maximum is ${formattedMoney(BIG_NUMBER)}`,
                    },
                    validate: {
                      validateMultipleOfHundred: validateMultipleOfHundred(
                        'Dwelling amount must be a multiple of $100'
                      ),
                    },
                  }}
                  render={({ field: { onChange, onBlur, name, value } }) => {
                    return (
                      <CurrencyInput
                        name={name}
                        id={name}
                        defaultValue={value}
                        decimalsLimit={2}
                        prefix="$"
                        onBlur={onBlur}
                        onValueChange={onChange}
                        allowNegativeValue={false}
                      />
                    )
                  }}
                />
                {errors?.additions_alterations_amount && (
                  <div className="dome-p3 dome-color-red-alert">{errors.additions_alterations_amount.message}</div>
                )}
              </div>
            </div>
            {/*<div className="policy-form__form-groups">
              <div className="policy-form__form-group input-interested-parties">
                <label htmlFor="interested_parties">
                  interested parties
                  <i
                    className="fa-light fa-circle-question dome-color-dark-green"
                    style={{ marginLeft: '6px' }}
                    data-tip
                    data-for="interested_parties_tooltip"
                  />
                </label>
                <InsuranceTooltip
                  id="interested_parties_tooltip"
                  header="Why is this here? "
                  content={`The property's policy requires the building to be listed as an additional part on the policy. `}
                />
                <input type="text" {...register('interested_parties')} id="interested_parties" />
                {errors?.interested_parties && (
                  <div className="dome-p3 dome-color-red-alert">{errors.interested_parties.message}</div>
                )}
              </div>
            </div>*/}
          </div>
        </div>
        {errorDescription ? <ErrorsFromServer error={errorDescription} /> : null}
        <div className="dome-d-flex dome-justify-between">
          <button
            type="button"
            className={clsx('dome-btn dome-btn-link dome-btn-link-back', !canGoBack && 'dome-btn-link-disabled')}
            onClick={goBack}
            disabled={!canGoBack}
          >
            change property
          </button>
          <button type="submit" className="dome-btn dome-btn-base dome-btn-green-stroke">
            next
          </button>
        </div>
        <div className="viaffinity-disclosure-mobile" style={{ marginTop: '24px' }}>
          <div className="dome-p3" style={{ marginBottom: '24px' }}>
            * Estimate based on property’s requirements, insurer’s recommendations, or expected values and is not final.
          </div>
          <ViaffinityDisclosureContent />
        </div>
      </form>
    </div>
  )
}

const PolicyCoverageDescription = ({ insuranceType, required }) => {
  const insuranceTypeCopy = insuranceType === 'renters' ? 'renter' : 'homeowner'
  const requiredCopy = required ? 'policy requirements' : 'policy recommendations'

  return (
    <div className="policy-coverage-description">
      <div>
        <i className="fa-light fa-sparkle" style={{ color: '#A8CB8F' }}></i>
      </div>
      <div>
        <p className="p2">
          {insuranceTypeCopy} {requiredCopy}
        </p>
        <p className="p3">
          {required
            ? 'Amounts set to meet to your property’s minimum requirements.'
            : "Amounts set based on insurer's recommendations."}
        </p>
      </div>
    </div>
  )
}
