import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import clsx from 'clsx'
import { isEmpty } from 'lodash-es'

import Switch from 'components/utility/switch'
import viaffinityLogo from '/app/assets/images/insurance/logos/viafinity.png'
import chubbLogo from '/app/assets/images/insurance/logos/chubb.png'
import loadingIcon from '/app/assets/images/insurance/icons/loader.gif'
import GetQuoteButton from '../button'
import { selectInsuranceQuoteInterstitialState, setStartingQuote } from '../slice/slice'
import { useGetStartingQuote } from '../hooks'
import MonetaryAmount from '../components/MonetaryAmount'

function ServerError() {
  return (
    <div className="quote-server-error dome-bg-fill">
      <div className="dome-p2 dome-color-dark-grey">The Chubb rating service is unavailable.</div>
      <div className="dome-p3-lt dome-color-dark-grey" style={{ fontSize: '10px' }}>
        If this problem persists, please contact{' '}
        <a href="mailto:info@domecile.com" className="dome-link dome-link-aqua" style={{ fontSize: '10px' }}>
          info@domecile.com
        </a>
        .
      </div>
    </div>
  )
}

function QuoteLoading() {
  return (
    <div className="quote-loading dome-d-flex dome-align-center">
      <div className="dome-loading-img">
        <img src={loadingIcon} width={95} height={95} />
      </div>
      <div>
        <div className="dome-p2 dome-color-dark-grey">We’re still working on the estimate for this property.</div>
        <div className="dome-p3-lt dome-color-dark-grey">In the meantime, continue to the next step.</div>
      </div>
    </div>
  )
}

function QuoteResult({ premium, insuranceType, all_requirements }) {
  const parsedPremium = parseInt(premium)
  const monthlyAmount = (isFinite(parsedPremium) ? parsedPremium : 0) / 12
  const required = all_requirements?.[`requires_${insuranceType}`]

  return (
    <div className="dome-d-flex dome-justify-between dome-align-center">
      <div className="dome-p1" style={{ flexBasis: '55%' }}>
        {required ? 'estimate based on requirements' : "estimate based on insurer's recommendations"}
      </div>
      <div className="dome-p1 dome-d-flex dome-align-baseline">
        <div className="dome-h2 dome-color-dark-green dome-d-flex dome-align-start" style={{ lineHeight: 1 }}>
          <MonetaryAmount amount={monthlyAmount} />
        </div>
        <span>/month</span>
      </div>
    </div>
  )
}

function QuoteServiceOffline() {
  return (
    <>
      <div className="quote-service-status">
        <div className="quote-service-status__title dome-p2">We’re sorry, this quote service is currently offline.</div>
        <div className="quote-service-status__description dome-p2-lt">
          Feel free to request a quote now and we’ll email you a link to it as soon as this service is back online.
        </div>
      </div>
    </>
  )
}

function EstimatedQuoteResult({
  building,
  onBack,
  canGoBack,
  active,
  packageId,
  insuranceType: defaultInsuranceType = 'homeowners',
}) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [started, setStarted] = useState(false)
  const [insuranceType, setInsuranceType] = useState(defaultInsuranceType)

  useEffect(() => {
    if (!active) setInsuranceType(defaultInsuranceType)
  }, [active])

  const {
    quote,
    fullScreenLoading,
    serverError,
    quoteServiceAvailability: { status: quoteServiceAvailable },
  } = useSelector(selectInsuranceQuoteInterstitialState)
  const startingQuote = useGetStartingQuote(building, insuranceType)

  const [premium, writingCompany] = useMemo(() => {
    if (quote && quote.insuranceType === insuranceType && quote.buildingId === building?.id && quote.premium) {
      return [quote.premium, quote.writingCompany]
    }

    if (startingQuote) {
      return [startingQuote.premium, startingQuote.writingCompany]
    }

    return [null, null]
  }, [quote, insuranceType, startingQuote, building?.id])

  useEffect(() => {
    if (fullScreenLoading.visible) {
      setLoading(fullScreenLoading.visible)
    }

    if (!fullScreenLoading.visible && started) {
      setLoading(false)
    }
  }, [fullScreenLoading])

  useEffect(() => {
    if (building?.id && !premium) setStarted(true)
  }, [building?.id])

  useEffect(() => {
    dispatch(setStartingQuote(isEmpty(startingQuote) ? null : startingQuote))
  }, [dispatch, startingQuote])

  const goBack = () => {
    onBack()
    setStarted(false)
  }

  const onInterstitialOpen = () => setStarted(true)
  const onInterstitialClose = () => {
    if (canGoBack) {
      goBack()
    }
  }

  const renderQuoteMain = () => {
    if (serverError) {
      return <ServerError />
    }

    if (loading || !premium) {
      return <QuoteLoading />
    }

    if (premium) {
      return (
        <QuoteResult
          premium={premium}
          insuranceType={insuranceType}
          all_requirements={building?.parsed_all_requirements}
        />
      )
    }
  }

  const handleToggleInsuranceType = (on) => {
    setInsuranceType(on ? 'renters' : 'homeowners')
  }

  return (
    <>
      <div className="estimated-result dome-d-flex dome-justify-between">
        <div className="dome-h2 dome-color-darkest-grey">{building?.title}</div>
        {active && <Switch offText="owner" onText="renter" onChange={handleToggleInsuranceType} />}
      </div>
      <div>
        <button
          className={clsx(
            'dome-btn dome-btn-link dome-btn-link-chevron-left dome-btn-link-aqua',
            !canGoBack && 'dome-btn-link-disabled'
          )}
          onClick={goBack}
          disabled={!canGoBack}
        >
          edit
        </button>
      </div>
      <hr />
      <div className="estimated-quote-result">{renderQuoteMain()}</div>
      {!quoteServiceAvailable && <QuoteServiceOffline />}
      <div className="get-quote-container">
        <div className="dome-d-flex dome-justify-between dome-align-center">
          <div className="quote-logos">
            <div>
              <em>by</em>
              <img src={viaffinityLogo} style={{ width: '56px' }} />
            </div>
            <div style={{ borderTop: '1px solid var(--disabled)' }}>
              <em>from</em>
              <img src={chubbLogo} style={{ width: '60px' }} />
            </div>
          </div>
          <GetQuoteButton
            building={building}
            package_id={packageId}
            started={started}
            onInterstitialOpen={onInterstitialOpen}
            onInterstitialClose={onInterstitialClose}
            insuranceType={insuranceType}
          />
        </div>
      </div>
      {writingCompany && (
        <div className="insurance-written-by dome-color-med-grey">Insurance underwritten by: {writingCompany}</div>
      )}
    </>
  )
}

export default EstimatedQuoteResult
