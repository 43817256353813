import React, {useState, useEffect, useRef, forwardRef} from 'react'

import Button from './Button'

const PasswordField = forwardRef((props, ref) => {
  const [isVisible, setIsVisible] = useState(false)

  const handleTogglePasswordVisibility = () => {
    const type = $("#password").attr("type") === "password" ? "text" : "password"
    $("#password").attr("type", type)
    setIsVisible(!isVisible)
  }

  const handleOnChange = () => {
    if ($(ref.current).val() !== "") {
      props.onKeyUp(true)
    } else {
      props.onKeyUp(false)
    }
  }

  const handleOnClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    props.updateStep(3)
  }

  useEffect(() => {
    if (props.currentStep == 2) {
      ref.current.focus()
    }
  }, [props.currentStep])

  return (
    <div className='input-group'>
      <label htmlFor='password'>password</label>
      <div style={{position: 'relative'}}>
        <i
          className={
            isVisible
              ? "fa-light fa-eye-slash"
              : "fa-light fa-eye"
          }
          onClick={handleTogglePasswordVisibility}
        ></i>
        <i className='fa-light fa-lock input-icons'></i>
        <input
          ref={ref}
          className='rounded-edges'
          type='password'
          id='password'
          name='user[password]'
          onKeyUp={handleOnChange}
          autoComplete='off'
          autoFocus={true}
          required={true}
        />
      </div>
      <Button className='forgot-password btn-link' type='button' onClick={handleOnClick}>
        forgot password?
      </Button>
    </div>
  )
})

export default PasswordField
