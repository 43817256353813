import React, { useState, useContext, useRef, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import { addCSRF } from 'lib/document_utilities'
import RenderConfirmModal from './RenderConfirmModal'
import RenderGroupedFees from './RenderGroupedFees'

const resetKeys = ["Reset", "Reset All"]

/*
 *  API Calls
 */

function submitFee({ url, hashed_id, fees, selected_option }) {

  const opts = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(addCSRF({ fees, hashed_id, selected_option }))
  }

  return new Promise((res, rej) => {
    fetch(url, opts)
      .then(response => response.text())
      .then(data => JSON.parse(data).error ? rej(JSON.parse(data).error) : res(JSON.parse(data).updated_fees))
      .catch(rej)
  })

}

function errorForFeeAction(error) {

  const errorMessage = () => {
    switch (typeof error) {
      case 'string':
        return error

      case Array.isArray(error):
        return error.join(' ')

      case 'object':
        return Object.values(error)

      default:
        return "Our server had an issue."
    }
  }

  const opts = {
    title: 'Error.',
    text: errorMessage(),
    type: "error",
    showCancelButton: true,
    closeOnConfirm: true,
  }

  return new Promise((res, rej) => swal(opts, ok => ok ? res() : rej()))
}

const FeeModal = ({ FeeTableContext, ModalContext, text, url, hashed_id }) => {
  const { fees, selectedFees, setFees, removeAllFees } = useContext(FeeTableContext)
  const { setCurrentModal, handleRadioChange, selectedOption, setSelectedOption, disabled } = useContext(ModalContext)
  const [showResetScreen, setResetScreen] = useState(resetKeys.includes(text))

  const submitFeeAction = () => submitFee({ url, hashed_id, fees: selectedFees.map(fee => fee.id), selected_option: selectedOption })
    .then((updated_fees) => { setFees(updated_fees); cleanup(); })
    .catch(errorForFeeAction)

  const cleanup = () => { $(modalRef.current).modal("hide"); removeAllFees(); }
  const modalRef = useRef()

  const feeActionProps = {
    text,
    cleanup,
    submitFeeAction,
  }

  useEffect(() => {
    const modal = $(modalRef.current)
    const close = () => { setCurrentModal(null); setResetScreen(false); setSelectedOption(null); }

    modal.modal("show")
    modal.on("hidden.bs.modal", close)

    return () => modal.off("hidden.bs.modal", close)
  }, [])

  const renderResetScreen = () => {
    return (
      <>
        <p className="fee-reset-header">You have selected a reset action.</p>
        <p className="fee-modal-sub-header">Please select reason for reset:</p>
        <div className="radio-btns">
          <Form.Check
            type="radio"
            label="Change Fee Amount"
            name="fee_option"
            value="reset_associated"
            onChange={handleRadioChange}
          />

          <Form.Check
            type="radio"
            name="fee_option"
            label="Unassign fee (not changing amount)"
            value="other"
            onChange={handleRadioChange}
          />
        </div>

        {selectedOption === 'reset_associated' ? <RenderGroupedFees fees={fees} selectedFees={selectedFees} /> : null}

        <div className="fee-buttons" style={{ marginTop: "3rem" }}>
          <button className="btn btn-danger auto-w" onClick={cleanup}>Cancel</button>
          <button disabled={disabled} className="btn btn-success auto-w fee-btn-disable" onClick={submitFeeAction}>Confirm</button>
        </div>
      </>
    )
  }

  return (
    <div ref={modalRef} className='modal fee-modal'>
      <div className='modal-box mobile-width body'>
        {showResetScreen ? renderResetScreen() : <RenderConfirmModal {...feeActionProps} />}
      </div>
    </div>
  )
}

export default FeeModal
