import React, { useState, useMemo, useContext, useCallback } from 'react'
import FeeModal from './FeeModal'
import FeeInfoAccordion from './FeeInfoAccordion'

//TO-DO: Refactor so we have our context in a file rather this being our parent file and passing context
//To child components

const FEE_BUTTON_INFO = [
  { text: "Reset", className: "btn btn-success fee-btn-disable", url: '/super/package_info/reset_fee' },
  { text: "Waive", className: "btn btn-warning fee-btn-disable", url: '/super/package_info/waive_fee' },
  { text: "Remove", className: "btn btn-danger fee-btn-disable", url: '/super/package_info/remove_fee' },
  { text: "Reset All", className: "btn btn-success fee-btn-disable", url: '/super/package_info/reset_fee', reset_all_fees: true }
]

// Helper Methods

const addOnce = (array, item) => array.includes(item) ? null : [...array, item]
const removeFee = (array, selected) => array.filter(item => item != selected)
const isCheckboxDisabled = (fee) => { return fee?.status === "captured" } //doesn't scale if we want more logic to disable checkbox per row
const allNonCapturedFees = (fees) => fees.filter(fee => fee?.status !== "captured")
const feeStatus = (status) => status == "not_authorized" ? "unassigned" : status

const optsTooltip = {
  "data-tooltip": "tooltip",
  "data-placement": "top",
  "title": "Cannot modify captured fees, please redirect to Payments Team."
}

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})
//old bootstrap jquery function needed to use tooltip

/*
/ Context Providers /
*/

const FeeTableContext = React.createContext({})
const ModalContext = React.createContext({})

function ModalProvider({ children }) {
  const [currentModal, setCurrentModal] = useState(null)
  const [selectedOption, setSelectedOption] = useState("other")
  //default other, we only care once selectedOption is set to reset_associated

  const disabled = !selectedOption
  const handleRadioChange = useCallback(e => setSelectedOption(e.target.value), [selectedOption])

  const value = useMemo(() => ({
    setCurrentModal,
    handleRadioChange,
    selectedOption,
    setSelectedOption,
    disabled

  }), [currentModal, selectedOption])

  return (
    <ModalContext.Provider
      value={value}
    >
      {children}
      {currentModal}
    </ModalContext.Provider>
  )
}

function FeeTableProvider({ fees: defaultFees, children }) {
  const [fees, setAllFees] = useState(defaultFees)
  const [selectedFees, setSelectedFees] = useState([])

  const selectedWatchers = [selectedFees, setSelectedFees]
  const selectAllViableFees = useCallback(() => setSelectedFees(allNonCapturedFees(fees)), [fees, setSelectedFees])
  const addSelectedFee = useCallback(fee => setSelectedFees(addOnce(selectedFees, fee)), selectedWatchers)
  const removeSelectedFee = useCallback(fee => setSelectedFees(removeFee(selectedFees, fee)), selectedWatchers)
  const removeAllFees = useCallback(() => setSelectedFees([]), selectedWatchers)
  const isSelected = useCallback(fee => selectedFees.includes(fee), [selectedFees])

  const setFees = useCallback(fees => {
    const feeIds = fees.map(fee => fee.id)

    setAllFees(fees);
    setSelectedFees(selectedFees.filter(fee => feeIds.includes(fee.id)))
  }, [...selectedWatchers, setAllFees])

  const value = useMemo(() => ({
    fees,
    selectedFees,
    addSelectedFee,
    removeSelectedFee,
    isSelected,
    setFees,
    selectAllViableFees,
    removeAllFees
  }), [fees, ...selectedWatchers])

  return <FeeTableContext.Provider value={value}>
    {children}

  </FeeTableContext.Provider>
}

/*
/ Table Components /
*/

function PackageFeeRow({ fee }) {
  const { addSelectedFee, removeSelectedFee, isSelected } = useContext(FeeTableContext)
  const [isAccordionOpen, setAccordionOpen] = useState(false)
  const showAccordion = () => setAccordionOpen(!isAccordionOpen)

  const checked = isSelected(fee)
  const disabled = isCheckboxDisabled(fee)
  const accordionIcon = isAccordionOpen ? "fa fa-minus" : "fa fa-plus"
  const tooltip = disabled ? optsTooltip : {}

  return (
    <div className="row-container">
      <div className="fee-table-row">
        <p className="fee-table-cell">
          <input {...tooltip}
            type="checkbox"
            className="fee-checkbox"
            checked={checked}
            disabled={disabled}
            onChange={() => !checked ? addSelectedFee(fee) : removeSelectedFee(fee)}
          />
        </p>
        <p className="fee-table-cell"> {fee?.name || "N/A"} </p>
        <p className="fee-table-cell"> {"$" + fee?.amount || "N/A"} </p>
        <p className="fee-table-cell"> {feeStatus(fee.status) || "N/A"} </p>
        <p className="fee-table-cell"> <i onClick={showAccordion} className={accordionIcon + " more-info-icon"} /></p>
      </div>
      {isAccordionOpen && <FeeInfoAccordion fee={fee} />}
    </div>
  )
}

function FeeButtons({ hashed_id, info }) {
  const { selectedFees, selectAllViableFees } = useContext(FeeTableContext)
  const { setCurrentModal } = useContext(ModalContext)
  const { text, reset_all_fees: reset_all, url } = info
  const disabled = true //reset_all ? false : selectedFees.length <= 0 //uncomment this when finalizing fee action logic

  const feeModalProps = {
    FeeTableContext,
    ModalContext,
    text,
    url,
    hashed_id
  }

  const confirmFeeAction = () => setCurrentModal(<FeeModal {...feeModalProps} />)
  const selectAllAndConfirm = () => { selectAllViableFees(); setCurrentModal(<FeeModal {...feeModalProps} />) }

  return (
    <>
      <button
        disabled={disabled}
        className={info.className}
        type="submit"
        onClick={reset_all ? selectAllAndConfirm : confirmFeeAction}
      >
        {text}
      </button>
    </>
  )
}

/*
/ Main Renders /
*/

function RenderTable() {
  const { fees } = useContext(FeeTableContext)

  return (
    <div className="fee-table-wrapper">
      <div className="fee-table">
        <div className="fee-table-row header-style">
          <p className="fee-table-cell">Selected</p>
          <p className="fee-table-cell">Fee Name</p>
          <p className="fee-table-cell">Fee Amount</p>
          <p className="fee-table-cell">Status</p>
          <p className="fee-table-cell">Expand</p>
        </div>
        {fees.map(fee => <PackageFeeRow key={fee.id} fee={fee} />)}
      </div>
    </div>
  )
}

const PackageFeesTable = ({ fees, hashed_id }) => {

  return (
    <FeeTableProvider fees={fees}>
      <ModalProvider>
        <RenderTable />
        <div className="fee-buttons">
          {FEE_BUTTON_INFO.map(info => <FeeButtons hashed_id={hashed_id} info={info} key={info.text} />)}
        </div>
      </ModalProvider>
    </FeeTableProvider>
  )
}

export default PackageFeesTable
