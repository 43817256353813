import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'

import Forms from './Forms'
import FlashMessage from './FlashMessage'

const LoginForm = (props) => {
  const inputRef = useRef(null)
  const inputRefPassword = useRef(null)
  const loginRef = useRef(null)
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [currentEmail, setCurrentEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [hasInput, setHasInput] = useState(false)
  const { register, handleSubmit, reset, setError, watch, formState: {errors, isDirty, isSubmitSuccessful}} = useForm({
    defaultValues: {
      user: {email: ''}
    }
  })

  const email = watch('user[email]')

  const handleClose = () => {
    props.closeOnClick ? props.closeOnClick(true) : null
  }

  useEffect(() => {
    if (props.modalState) {
      reset()
      setCurrentStep(1)
      setHasInput(false)
    }
  }, [props.modalState])

  useEffect(() => {
    if (currentStep !== 2) {
      inputRef.current.focus()
    }
    reset({user: {email: ''}},{keepValues: true, keepDirty: true})
    const re = /^.+@.+\..+$/
    setIsValidEmail(re.test(email))

  }, [currentStep])

  useEffect(() => {
    const subscription = watch(data => {
      if (isValidEmail) return

      let user = data.user.email
      setCurrentEmail(user)
      RegExp(/^.+@.+\..+$/).test(user) ? setIsValidEmail(true) : setIsValidEmail(false)
    })

    return () => subscription.unsubscribe()
  }, [watch])

  const checkSSO = (data) => {
    if (inputRef.current.value.length < 1) {
      setError("user[email]", {
        type: "manual",
        message: "Email field can't be empty.",
      })
      setIsLoading(false)
      return
    }

    setIsLoading(true)
    $.ajax({
      url: "/check_sso",
      data: { 'email': data.user.email },
      context: this,
      method: "GET",
      dataType: "JSON",
    })
    .done((res) => {
      if (currentStep === 1) {
        res.sso_url ? window.location.assign(res.sso_url) : setCurrentStep(2)
        setIsLoading(false)
        reset({user: {email: ''}}, {keepValues: true})
      }
    })
  }

  const postLogin = () => {
    const form = $('.login-form')

    $.ajax({
      url: form.attr("action"),
      data: form.serialize(),
      context: this,
      method: "POST",
      dataType: "JSON",
    })
      .done(res => window.location.assign(res.redirect_url))
      .fail(res => {
        setError("user[email]", {
          type: "manual",
          message: res.responseJSON.error,
        })
        reset({ user: { email: "" } }, { keepValues: true, keepErrors: true })
      })
  }

  const sendRecovery = () => {
    const form = $(".login-form")

    $.ajax({
      url: form.attr("action"),
      data: form.serialize(),
      context: this,
      method: "POST",
      dataType: "JSON",
    })
      .done(() => {
        props.updateParentState(true)
        props.updateEmailState(currentEmail)
        handleClose()
      })
      .fail(() => {
        props.updateParentState(true)
        handleClose()
      })
  }

  const updatePasswordFieldState = (newValue) => setHasInput(newValue)
  const updateStep = step => setCurrentStep(step)
  return (
    <>
      <div ref={loginRef} className='login-form-container'>
        <div className='login-form-contents'>
          <div className='login-header'>{currentStep == 1 || currentStep == 2 ? props.loginHeader : props.forgotPassHeader}</div>
          {errors?.user?.email && <p className='error'>{errors?.user?.email?.message}</p>}
          <FlashMessage classes='display-only-mobile-flex' display='display-only-mobile' flashMessage={props.flashMessages} />
          <Forms { ... { currentStep, checkSSO, postLogin, sendRecovery, handleSubmit, inputRef, register, isLoading, reset, isDirty, isSubmitSuccessful, updateStep, inputRefPassword, updatePasswordFieldState, hasInput, isValidEmail, errors } }
          />
          <div className='login-footer display-only-desktop'>
            Don't have an account? <a className='link-aqua link-chevron-right' href='/register'>sign up</a>
          </div>
          <div className="fullpage-footer display-only-mobile">
            <div className='login-footer'>
              Don't have an account? <a className='link-aqua link-chevron-right' href='/register'>sign up</a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


LoginForm.defaultProps = {
  loginHeader: <header>welcome back!</header>,
  forgotPassHeader: <header>forgot your password?</header>
}
export default LoginForm
