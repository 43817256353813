import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateInvoice } from "../../InvoiceEdit/store/slice";
import {
  addInvoiceServiceApi,
  destroyInvoiceApi,
  destroyInvoiceServiceApi,
  shareInvoiceApi,
  updateInvoiceServiceApi,
} from "./apis";

const initialState = {
  services: {},
  categories: [],
  invoice: null,
  invoice_services: {},
  sub_merchants: {},
  addedServiceIds: [],
  category_services: {},
  activeCategoryId: null,
  invoiceCancelled: false,
  cancelledInvoiceAddress: "",
};

export const addInvoiceService = createAsyncThunk(
  "residentPaymentsInvoicePage/addInvoiceService",
  async ({ service_id, invoice_id, quantity }) => {
    const response = await addInvoiceServiceApi({ service_id, invoice_id, quantity });
    const { invoice_service } = response.data;
    return { invoice_service };
  }
);

export const updateInvoiceService = createAsyncThunk(
  "residentPaymentsInvoicePage/updateInvoiceService",
  async ({ service_id, invoice_id, quantity }) => {
    const response = await updateInvoiceServiceApi({ service_id, invoice_id, quantity });
    const { invoice_service } = response.data;
    return { invoice_service };
  }
);

export const destroyInvoiceService = createAsyncThunk(
  "residentPaymentsInvoicePage/destroyInvoiceService",
  async ({ invoice_id, service_id }) => {
    const response = await destroyInvoiceServiceApi({ service_id, invoice_id });
    const { invoice_service } = response.data;
    return { invoice_service };
  }
);

export const cancelInvoice = createAsyncThunk("residentPaymentsInvoicePage/cancelInvoice", async ({ invoice_id }) => {
  const response = await destroyInvoiceApi({ invoice_id });
  const { invoice } = response.data;
  return { invoice };
});

export const shareInvoice = createAsyncThunk("residentPaymentsInvoicePage/shareInvoice", async ({ invoice_id, note }) => {
  const response = await shareInvoiceApi({ invoice_id, note });
  const { invoice } = response.data;
  return { invoice };
});

const paymentPageSlice = createSlice({
  name: "residentPaymentsInvoicePage",
  initialState,
  reducers: {
    setInitialState: (state, action) => {
      state.categories = action.payload.categories;
      state.invoice = action.payload.invoice;

      action.payload.services.forEach((service) => {
        state.services[service.id] = service;

        state.category_services[service.category_id] ||= [];
        state.category_services[service.category_id].push(service.id);
      });

      action.payload.invoice_services.forEach((invoice_service) => {
        state.invoice_services[invoice_service.service_id] = invoice_service;
        state.addedServiceIds.push(invoice_service.service_id);
      });

      action.payload.sub_merchant_dropdown.forEach(([description, id]) => {
        state.sub_merchants[id] = description;
      });
    },
    setActiveCategoryId: (state, action) => {
      state.activeCategoryId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addInvoiceService.fulfilled, (state, action) => {
      const { invoice_service } = action.payload;
      state.invoice_services[invoice_service.service_id] = invoice_service;

      if (!state.addedServiceIds.find((invoiceServiceId) => invoiceServiceId === invoice_service.id)) {
        state.addedServiceIds.push(invoice_service.service_id);
      }
    });
    builder.addCase(updateInvoiceService.fulfilled, (state, action) => {
      const { invoice_service } = action.payload;
      state.invoice_services[invoice_service.service_id] = invoice_service;
    });
    builder.addCase(destroyInvoiceService.fulfilled, (state, action) => {
      const { service_id } = action.meta.arg;
      delete state.invoice_services[service_id];

      const serviceIndex = state.addedServiceIds.findIndex((serviceId) => serviceId === service_id);
      if (serviceIndex !== -1) {
        state.addedServiceIds.splice(serviceIndex, 1);
      }
    });
    builder.addCase(shareInvoice.fulfilled, (state, action) => {
      const { invoice } = action.payload;
      state.invoice = invoice;
    });
    builder.addCase(updateInvoice.fulfilled, (state, action) => {
      const { invoice } = action.payload;
      state.invoice = invoice;
    });
    builder.addCase(cancelInvoice.fulfilled, (state, action) => {
      const { invoice } = action.payload;
      state.cancelledInvoiceAddress = invoice.building_address;
      state.invoiceCancelled = true;
    });
  },
});

export const selectInvoicePageState = (state) => state.residentPaymentsInvoicePage;

export const { setInitialState, setActiveCategoryId } = paymentPageSlice.actions;

export const residentPaymentsInvoicePageReducer = paymentPageSlice.reducer;
