import axios from "lib/axios";

export const addInvoiceServiceApi = async ({ service_id, invoice_id, quantity }) => {
  return await axios.post("/resident_payments/invoices/invoice_service", {
    service_id,
    invoice_id,
    quantity,
  });
};

export const updateInvoiceServiceApi = async ({ service_id, invoice_id, quantity }) => {
  return await axios.patch(`/resident_payments/invoices/invoice_service/${service_id}`, {
    quantity,
    invoice_id,
  });
};

export const destroyInvoiceServiceApi = async ({ service_id, invoice_id }) => {
  return await axios.delete(`/resident_payments/invoices/invoice_service/${service_id}`, {
    data: { invoice_id },
  });
};

export const destroyInvoiceApi = async ({ invoice_id }) => {
  return await axios.delete(`/resident_payments/invoices/${invoice_id}`)
};

export const shareInvoiceApi = async ({ invoice_id, note }) => {
  return await axios.post(`/resident_payments/invoices/${invoice_id}/share`, { note });
};
