import React, { useState } from 'react'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { isFinite } from 'lodash'
import { setLegalDisclaimerModalVisibility } from 'components/insurance/get_quote/slice/slice'

import ViaffinityDisclosureContent from '../../ViaffinityDisclosureContent'
import FinalizeQuoteSidebarHeadline from './FinalizeQuoteSidebarHeadline'
import FinalizeQuoteSidebarDetail from './FinalizeQuoteSidebarDetail'
import QuoteStatusContent from './QuoteStatusContent'
import QuoteEstimateMonthlyContent from './QuoteEstimateMonthlyContent'
import ExpandableText from '../../ExpandableText'

export default function FinalizeQuoteSidebar({ quote, insuranceType, sidebarDetails }) {
  const {
    quoteNumber,
    premium,
    liabilityLimit,
    homeAndContentsDeductible,
    writingCompany,
    policyEffectiveDate,
    policyExpirationDate,
    contentsAmount,
    additionsAndAlterationsAmount,
  } = quote
  const parsedPremium = parseInt(premium)
  const monthlyAmount = (isFinite(parsedPremium) ? parsedPremium : 0) / 12
  const [mobileExpanded, setMobileExpanded] = useState(true)
  const onToggleExpand = () => setMobileExpanded((mobileExpanded) => !mobileExpanded)

  const dispatch = useDispatch()

  return (
    <>
      <div className="sidebar-desktop">
        <div className="finalize-quote-sidebar-desktop dome-bg-white dome-dark-shadow dome-rounded-border">
          <div className="sidebar-headline">
            <div className="finalize-quote-risk-type-and-quote-monthly">
              <QuoteStatusContent quoteId={quoteNumber} insuranceType={insuranceType} />
            </div>
            <QuoteEstimateMonthlyContent monthlyAmount={monthlyAmount} writingCompany={writingCompany} />
          </div>
          <div className="sage-line my-18"></div>
          <FinalizeQuoteSidebarDetail
            liabilityLimit={liabilityLimit}
            homeAndContentsDeductible={homeAndContentsDeductible}
            policyEffectiveDate={policyEffectiveDate}
            policyExpirationDate={policyExpirationDate}
            contentsAmount={contentsAmount}
            additionsAndAlterationsAmount={additionsAndAlterationsAmount}
            sidebarDetails={sidebarDetails}
          />
        </div>

        <div className="viaffinity-disclosure-desktop dome-bg-white dome-dark-shadow dome-rounded-border">
          <div className="dome-p3" style={{ marginBottom: '24px' }}>
            <ExpandableText onClickExpandButton={() => dispatch(setLegalDisclaimerModalVisibility(true))} />
          </div>
          <ViaffinityDisclosureContent />
        </div>
      </div>

      <div className="sidebar-mobile">
        <div className="finalize-quote-sidebar-mobile">
          <div className="sidebar-headline">
            <FinalizeQuoteSidebarHeadline
              monthlyAmount={monthlyAmount}
              writingCompany={writingCompany}
              mobileExpanded={mobileExpanded}
              onToggleExpand={onToggleExpand}
            />
          </div>
          <div className={clsx('sidebar-content', mobileExpanded ? '' : 'collapsed')}>
            <div className="dome-bg-white dome-dark-shadow dome-rounded-border">
              <div className="finalize-quote-risk-type-and-quote-monthly">
                <QuoteStatusContent quoteId={quoteNumber} insuranceType={insuranceType} />
              </div>
              <FinalizeQuoteSidebarDetail
                liabilityLimit={liabilityLimit}
                homeAndContentsDeductible={homeAndContentsDeductible}
                policyEffectiveDate={policyEffectiveDate}
                policyExpirationDate={policyExpirationDate}
                contentsAmount={contentsAmount}
                additionsAndAlterationsAmount={additionsAndAlterationsAmount}
                sidebarDetails={sidebarDetails}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
