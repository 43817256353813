import React, {forwardRef} from 'react'

const errorTextRed = {color: '#b30000'}
const errorBorderRed = {borderColor: '#b30000'}

const EmailField = forwardRef((props, inputRef) => {

  const handleClick = (e) => {
    e.stopPropagation()
    if (props.currentStep == 1 || props.onClick === undefined) return

    props.onClick(props.currentStep - 1)
  }

  const clearErrorsOnChange = () => {
    if (inputRef.current.value.length < 1) {
      props.reset()
    }
  }

  const { ref, ...rest } = props.register("user[email]", {
    pattern: {
      value: /^.+@.+\..+$/,
      message: "Enter a valid email address.",
    },
    onChange: clearErrorsOnChange,
  })

  return (
    <div className='input-group'>
      <label htmlFor='email'>email</label>
      <div style={{position: 'relative'}}>
        <i
          className='fa-light fa-envelope input-icons'
          style={props.errors?.user?.email && errorTextRed}
        ></i>
        <div className='overlay-for-disabled-input' onClick={handleClick}>
          <input
            {...rest}
            name={"user[email]"}
            ref={e => {
              ref(e)
              inputRef.current = e
            }}
            style={props.errors?.user?.email && errorBorderRed}
            className='rounded-edges'
            type='email'
            id='email'
            readOnly={props.currentStep === 2}
          />
        </div>
      </div>
    </div>
  )
})

export default EmailField
