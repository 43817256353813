import React from 'react'
import EmailField from './EmailField'
import PasswordField from './PasswordField'
import Button from './Button'
import LoadingIcon from './LoadingIcon'

const StandardLogin = (props) => {
  return (
    <div>
      <EmailField
        ref={props.inputRef}
        register={props.register}
        currentStep={props.currentStep}
        onClick={props.updateStep}
        reset={props.reset}
      />
      <PasswordField ref={props.inputRefPassword} onKeyUp={props.onKeyUp} updateStep={props.updateStep} currentStep={props.currentStep}/>
      <Button type='submit' disabled={!props.hasInput} className={`btn-base ${props.hasInput ? "btn-dark-green" : "btn-disabled"}`} id="log-in-button">
        {props.isLoading ? (
          <i className="fa-duotone fa-spinner fa-spin-pulse"></i>
        ) : (
          "login"
        )}
      </Button>
    </div>
  )
}

export default StandardLogin
