import React from 'react'
import { map, filter, includes, find, compact, groupBy } from 'lodash'

const getFeesByChargeId = (fees, selectedFees) => {
  const selectedChargeIds = map(selectedFees, 'stripe_charge_id');

  const filteredFees = filter(fees, (fee) => {
    return includes(selectedChargeIds, fee?.stripe_charge_id) || find(selectedFees, { stripe_charge_id: fee?.stripe_charge_id });
  })

  const compactedFees = compact(filteredFees.map(fee => fee?.stripe_charge_id ? fee : null))
  const groupedFees = groupBy(compactedFees, (fee) => fee?.stripe_charge_id)

  return groupedFees
}

const renderGroupedFees = (groupedFees) => {

  if (Object.entries(groupedFees).length === 0)
    return <div>Selected fees do not belong to any transaction group.</div>

  else
    return (
      <>
        <h3 className="reset-warning">Warning.<br />
          <span>The following fees will be reauthorized:</span>
        </h3>
        {Object.entries(groupedFees).map(([groupId, feesInGroup]) => (
          <div className="group" key={groupId}>
            {feesInGroup.map(fee => (
              <div key={fee.id}>
                <p>{fee.name} (${fee.amount})</p>
              </div>
            ))}
            <br />
          </div>
        ))}
      </>
    )
}

const RenderGroupedFees = ({ fees, selectedFees }) => {
  const groupedFees = getFeesByChargeId(fees, selectedFees)
  const groupedFeesToRender = renderGroupedFees(groupedFees)

  return (
    <div className="group-wrapper">{groupedFeesToRender}</div>
  )
}

export default RenderGroupedFees
