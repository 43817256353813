import React, { createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { has, compact, isFinite, isNull, pick, omit } from 'lodash'
import { connectToEventManager } from '@deathbyjer/react-event-manager'

const MIN_DRAG = 100

const ENTER_KEY = 13
const SHIFT_KEY = 16
const CTRL_KEY = 17

const LEFT_KEY = 37
const RIGHT_KEY = 39
const UP_KEY = 38
const DOWN_KEY = 40

const RECT_ATTRIBUTES = ['x', 'y', 'width', 'height']
const DIRECTION_KEYS = [LEFT_KEY, RIGHT_KEY, UP_KEY, DOWN_KEY]

export const componentCallbacks = ['onRectUpdated', 'onSelected', 'onUnselected']

export function idAsString(id) {
    if (Array.isArray(id))
    return id.join("-")

  return id
}

export function eventForId(event, id) {
  return `${event}:${idAsString(id)}`
}

const DEFAULT_DRAWN_RECT = {
  x: 40, y: 20,
  width: 20, height: 10
}

function percentageOrDefault(rect) {
  const out = {}
  for (let prop of ['x', 'y', 'width', 'height']) {
    out[prop] = has(rect, prop) ? String(rect[prop]).replaceAll(/%/g, '') : DEFAULT_DRAWN_RECT[prop]
    out[prop] = parseFloat(out[prop])
  }

  return out
}

const toNumber = x => isNull(x) ? null : parseFloat(x)

function positionChanged(prevProps, props) {
  const prevRect = {}
  const rect = {}

  for (let pos of RECT_ATTRIBUTES) {
    rect[pos] = (props.rect ? props.rect[pos] : null) || props[pos]
    prevRect[pos] = (prevProps.rect ? prevProps.rect[pos] : null) || prevProps[pos]
  }

  for (let pos of RECT_ATTRIBUTES)
    if (rect[pos] != prevRect[pos])
      return true

  return false
}

function rectAbs(rect, lock_size = false) {
  const out = {...rect}

  if (out.width < 0) {
    out.x += out.width
    out.width = -out.width
  }

  if (out.height < 0) {
    out.y += out.height
    out.height = -out.height
  }

  if (lock_size) {
    if (out.x + out.width > 100)
      out.x = 100 - out.width

      if (out.y + out.height > 100)
      out.y = 100 - out.height
  } else {
    if (out.x + out.width > 100)
      out.width = 100 - out.x

    if (out.y + out.height > 100)
      out.height = 100 - out.y
  }

  return out
}

export function RectWrapper(props) {
  const subProps = omit(props, ['locked', 'active', 'drawOnCreate', 'onRectUpdated', 'onSelected', 'onUnselected'])

  return <div {...subProps} />
}

function getScrollableAncestors(container, list = []) {
  if (!container)
    return list


  if (container.scrollHeight > container.clientHeight)
    list.push(container)

  if (container.parentElement)
    getScrollableAncestors(container.parentElement, list)

  return list
}

class DrawnRectInternal extends React.Component {
  constructor(props) {
    super(props)

    this.container = React.createRef()

    this.classes = new Set([
      'drawn-react-component'
    ])

    this.state = {}

    this.rect = this.startingRect()
    this.startMovement = null
    this.selected = false
    this.resizeFrom = null
    this.lock_size = false

    this.moveRectFromChild = this.moveRectFromChild.bind(this)
    this.setChildId = this.setChildId.bind(this)
  }

  componentDidMount() {
    this.props.draw_context.addChild(this)
    // If we are generating this on a mousedown...
    if (this.props.drawOnCreate) {
      this.forceStartMove('corner', 'se')
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.locked) {
      this.props.draw_context.removeChild(this)
      delete this.moving
      this.selected = false
      this.resizeFrom = null
      this.lock_size = null
      this.startMovement = null
    } else {
      this.props.draw_context.addChild(this)
    }

    if (positionChanged(prevProps, this.props))
      this.rect = this.startingRect()
  }

  componentWillUnmount() {
    this.props.draw_context.removeChild(this)
  }

  startingRect() {
    return percentageOrDefault(this.props)
  }

  style() {
    return {
      left: `calc(${this.rect.x}% - 5px)`,
      top: `calc(${this.rect.y}% - 5px)`,
      width: `calc(${this.rect.width}% + 10px)`,
      height: `calc(${this.rect.height}% + 10px)`
    }
  }

  childIdAsString() {
    if (Array.isArray(this.childId))
      return this.childId.join("-")

    return this.childId
  }

  setChildId(id) {
    this.childId = id
  }

  select() {
    if (this.props.locked)
      return

    this.selected = true
    this.classes.add("draw-area-selected")
    this.resetClasses()

    this.props.draw_context.selectChild(this)
    if (typeof this.props.onSelect == "function")
      this.props.onSelect()

    this.setState({selected: true})
  }

  unselect() {
    if (this.props.locked)
      return

    this.selected = false
    this.classes.delete("draw-area-selected")
    this.resetClasses()

    if (typeof this.props.onUnselect == "function")
      this.props.onUnselect()


    this.setState({selected: null})
  }

  track(type, tracking) {
    if (this.props.locked)
      return

    if (this.props.draw_context.hasGroup())
      return

    if (this.moving?.tracking)
      this.classes.delete(`resize-${this.moving.tracking}`)

    switch(type) {
    case 'area':
      if (this.moving)
        return
      break
    case 'side':
      if (['side', 'corner'].includes(this.moving?.type))
        return
      break
    case 'corner':
      if (this.moving?.type == 'corner')
        return
      break
    }

    this.forceStartMove(type, tracking)
  }

  isActive() {
    return this.moving || this.selected
  }

  hasDescendant(node) {
    return this.container.current?.contains(node)
  }

  keyMove({x: deltaX, y: deltaY}) {
    const rect = { ... this.rect }
    rect.x += deltaX
    rect.y += deltaY
    this.rect = rectAbs(rect, this.lock_size)
    this.moveContainer()
  }

  rectUpdated() {
    if (this.props.onRectUpdated)
      this.props.onRectUpdated(this.rect)

    const event = {
      rect: pick(this.rect, RECT_ATTRIBUTES),
      id: this.childId
    }

    this.props.events.applyEventListeners('rect-updated', event)
    this.props.events.applyEventListeners(`rect-updated:${this.childIdAsString()}`, event)
  }

  finishedKeyMove() {
    this.rectUpdated()
  }

  forceStartMove(type = "area", tracking = "area") {
    this.moving = { start: this.rect, tracking, type}

    this.lock_size = true
    this.classes.add(`resize-${tracking}`)
    this.classes.add("moving")
    this.resetClasses()

    this.props.draw_context.activateChild(this)
  }

  move({x: deltaX, y: deltaY, tracking}) {
    if (!this.moving)
      return

    const rect = { ... this.moving.start }
    switch(this.moving.tracking) {
    case 'area':
      rect.x += deltaX
      rect.y += deltaY
      break
    case 'top':
      rect.y += deltaY
      rect.height -= deltaY
      break
    case 'bottom':
      rect.height += deltaY
      break
    case 'left':
      rect.x += deltaX
      rect.width -= deltaX
      break
    case 'right':
      rect.width += deltaX
      break
    case 'nw':
      rect.x += deltaX
      rect.y += deltaY
      rect.width -= deltaX
      rect.height -= deltaX
      break
    case 'ne':
      rect.y += deltaY
      rect.height -= deltaY
      rect.width += deltaX
      break
    case 'sw':
      rect.x += deltaX
      rect.width -= deltaX
      rect.height += deltaY
      break
    case 'se':
      rect.width += deltaX
      rect.height += deltaY
      break
    }

    if (this.props.lockHorizontalScale)
      rect.width = this.moving.start.width
    if (this.props.lockVerticalScale)
      rect.height = this.moving.start.height

    this.rect = rectAbs(rect, this.lock_size)
    this.moveContainer()
  }

  moveContainer() {
    if (!this.container.current)
      return

    const style = this.style()
    for (let attr of ['left', 'top', 'width', 'height'])
      this.container.current.style[attr] = style[attr]
  }

  finishedMoving() {
    if (!this.moving)
      return

    this.classes.delete(`resize-${this.moving.tracking}`)
    this.classes.delete("moving")
    this.resetClasses()

    this.props.draw_context.deactivateChild(this)
    this.rectUpdated()
    this.moving = null
  }

  resetClasses() {
    if (!this.container.current)
      return

    const classes = compact(Array.from(this.classes).concat([
      this.props.locked ? 'locked' : null
    ]))

    this.container.current.className = classes.join(" ")
  }

  render() {
    const style = this.style()

    const mousedown = () => {
      this.track('area', 'area')
      this.select()
    }

    const classes = Array.from(this.classes)
    if (this.props.locked)
      classes.push("locked")
    if (this.props.lockHorizontalScale)
      classes.push("lock-horizontal")
    if (this.props.lockVerticalScale)
      classes.push("lock-vertical")

    if (this.props.active)
      classes.push("active")

    return <div ref={this.container} style={style} className={classes.join(" ")} draggable={false}>
      <div className="area" onMouseDown={mousedown}>
        { this.renderChild() }
      </div>
      { this.renderSides() }
      { this.renderCorners() }
    </div>
  }

  moveRectFromChild(rect, allowUpdate = false) {
    this.rect = percentageOrDefault(rect)
    this.moveContainer()
    if (allowUpdate)
      this.rectUpdated()
  }

  renderChild() {
    const localProps = {
      moveParentRect: this.moveRectFromChild,
      setId: this.setChildId,
      selected: this.state.selected
    }
    const props = { ...localProps,  ...this.props.children.props }

    return React.cloneElement(this.props.children, props)
  }

  renderSides() {
    if (this.props.locked)
      return null

    const track = side => ( () => this.track('side', side) )

    const horizontals = <>
      <div className="side left" onMouseDown={track('left')}></div>
      <div className="side right" onMouseDown={track('right')}></div>
    </>

    const verticals = <>
      <div className="side top" onMouseDown={track('top')}></div>
      <div className="side bottom" onMouseDown={track('bottom')}></div>
    </>

    return <>
      { this.props.lockHorizontalScale ? null : horizontals }
      { this.props.lockVerticalScale ? null : verticals }
    </>
  }

  renderCorners() {
    if (this.props.locked || this.props.lockHorizontalScale || this.props.lockVerticalScale)
      return null

    const track = corner => ( () => this.track('corner', corner) )
    return <>
      <div className="corner nw" onMouseDown={track('nw')}></div>
      <div className="corner ne" onMouseDown={track('ne')}></div>
      <div className="corner sw" onMouseDown={track('sw')}></div>
      <div className="corner se" onMouseDown={track('se')}></div>
    </>
  }
}

const DrawnRect = connectToEventManager(DrawnRectInternal)

const childrenInSet = set => Array.from(set).map(item => item.childId)

class DrawArea extends React.Component {
  constructor(props) {
    super(props)

    this.rect = { x: 0, y: 0 }
    this.setupApi()
    this.setupMonitoring()

    this.container = React.createRef()
    this.children = new Set()
    this.activeChildren = new Set()

    this.selectedChildren = new Set()
    this.keysPressed = {}
  }

  fullyLocked() {
    return this.props.locked
  }

  setupApi() {
    this.api = {
      getContainer: () => this.container.current,
      hasGroup: () => this.selectedChildren.size > 1,
      addChild: component => this.addChild(component),
      activateChild: component => this.activateChild(component),
      deactivateChild: component => this.deactivateChild(component),
      selectChild: component => this.selectChild(component),
      removeChild: component => this.removeChild(component)
    }
  }

  setupMonitoring() {
    this.monitors = {
      mouseDown: evt => this.startDragging(evt),
      mouseMove: evt => this.monitorDragging(evt),
      mouseUp: evt => this.stopDragging(evt),
      resize: evt => this.resizeSelf(evt),
      scroll: evt => this.resizeSelf(evt),
      click: evt => this.clickOff(evt),
      keydown: evt => this.windowMonitorKeydown(evt),
      keyup: evt => this.windowMonitorKeyup(evt),
      drop: evt => this.onDrop(evt),
      dragover: evt => this.onDragOver(evt),
    }
  }

  addChild(component) {
    this.children.add(component)

    if (component.isActive())
      this.activeChildren.add(component)
  }

  activateChild(component) {
    if (component.isActive())
      this.activeChildren.add(component)
  }

  selectChild(component) {
    if (!this.isMultiselect()) {
      for (let child of Array.from(this.selectedChildren)) {
        if (child != component) {
          child.unselect()
          this.selectedChildren.delete(child)
        }
      }

      if (this.props.onUnselectAll)
        this.props.onUnselectAll()
    }

    this.selectedChildren.add(component)

    if (this.props.onUpdatedSelected)
      this.props.onUpdatedSelected(childrenInSet(this.selectedChildren))
  }

  unselectAll(internal = false) {
    const children = Array.from(this.selectedChildren)

    // We only need to unselect stuff if there is stuff to unselect
    if (children.length > 0) {

      for (let child of children) {
        child.unselect()
        this.selectedChildren.delete(child)
      }

      if (this.props.onUpdatedSelected)
        this.props.onUpdatedSelected([])
    }

    if (internal && this.props.onUnselectAll)
      this.props.onUnselectAll()
  }

  deactivateChild(component) {
    if (!component.isActive())
      this.activateChild.delete(component)
  }

  removeChild(component) {
    this.children.delete(component)
    this.activeChildren.delete(component)
  }

  relativePoint({x, y}) {
    return {
      x: isFinite(x) ? x - this.rect.x : null,
      y: isFinite(y) ? y - this.rect.y : null
    }
  }

  pointAsPercent({x, y}) {
    return {
      x: isFinite(x) ? x * 100 / this.rect.width : null,
      y: isFinite(y) ? y * 100 / this.rect.height : null
    }
  }

  relativePointByPercent({x, y}) {
    return this.pointAsPercent(this.relativePoint({x, y}))
  }

  clickOff(evt) {
    if (this.isMultiselect())
      return

    this.unselectAll(true)
  }

  directionKeyIsPressed() {
    return DIRECTION_KEYS.some(key => this.keysPressed[key])
  }

  accelerateKeyMovements() {
    return this.keysPressed[SHIFT_KEY]
  }

  startMovingFromKey(timeout = 25) {
    if (!this.movingFromKeyTimeout)
      this.movingFromKeyTimeout = setTimeout(() => this.movingFromKey(), timeout)
  }

  stopMovingFromKey() {
    clearTimeout(this.movingFromKeyTimeout)
    delete this.movingFromKeyTimeout
  }

  movingFromKey() {
    if (!DIRECTION_KEYS.some(key => this.keysPressed[key]))
      return

    this.stopMovingFromKey()
    this.moveFromKeysPressed()
    this.startMovingFromKey()
  }

  moveFromKeysPressed() {
    let deltaX = 0
    let deltaY = 0

    const movement_speed = this.accelerateKeyMovements() ? 5 : 1

    if (this.keysPressed[LEFT_KEY])
      deltaX -= movement_speed
    if (this.keysPressed[RIGHT_KEY])
      deltaX += movement_speed

    if (this.keysPressed[UP_KEY])
      deltaY -= movement_speed
    if (this.keysPressed[DOWN_KEY])
      deltaY += movement_speed

    const x = deltaX * 100 / this.rect.width
    const y = deltaY * 100 / this.rect.height

    for (let child of this.selectedChildren)
      child.keyMove({x, y})
  }

  startDragging(evt) {
    if (this.fullyLocked())
      return

    const x = evt.pageX
    const y = evt.pageY

    this.start = this.relativePoint({x, y})
    this.current = this.relativePoint({x, y})

    // If we have more than one child selected, then
    // we need to move ALL of them.
    if (this.selectedChildren.length > 1)
      for (let child of this.selectedChildren)
        child.forceStartMove()

    // If they aren't clicking on something
    if (!Array.from(this.children).some(child => child.hasDescendant(evt.target))) {
      if (this.props.onEmptyMousedown) {
        const point = this.relativePointByPercent({x, y})
        this.props.onEmptyMousedown(point)
      }

      this.clickOff()
    }
  }

  monitorDragging(evt) {
    if (!this.start)
      return

    const x = evt.pageX
    const y = evt.pageY
    this.current = this.relativePoint({x, y})

    const deltaX = this.current.x - this.start.x
    const deltaY = this.current.y - this.start.y
    const percX = deltaX * 100 / this.rect.width
    const percY = deltaY * 100 / this.rect.height

    this.moveChildren({x: percX, y: percY})
  }

  stopDragging() {
    for (let child of this.activeChildren)
      child.finishedMoving()

    this.start = this.current = null
  }

  onDragOver(evt) {
    if (this.fullyLocked())
      return

    evt.preventDefault()
  }

  onDrop({ dataTransfer: dt, pageX, pageY }) {
    if (this.fullyLocked())
      return

    const componentId = dt.getData('component-id')
    if (!componentId)
      return

    const point = this.relativePointByPercent({
      x: pageX - (toNumber(dt.getData("starting-offset-x")) || 0),
      y: pageY - (toNumber(dt.getData("starting-offset-y")) || 0)
    })

    const size = this.pointAsPercent({
      x: toNumber(dt.getData("component-width")),
      y: toNumber(dt.getData("component-height"))
    })

    const rect = {
      ...point,
      ...{width: size.x, height: size.y}
    }

    if (this.props.onDrop)
      this.props.onDrop(componentId, rect, dt)
  }

  windowMonitorKeydown(evt) {
    if (this.fullyLocked())
      return

    switch(evt.keyCode) {
    case SHIFT_KEY:
      this.keysPressed[SHIFT_KEY] = true
      break
    case CTRL_KEY:
      this.keysPressed[CTRL_KEY] = true
      break
    case LEFT_KEY:
      this.keysPressed[LEFT_KEY] = true
      break
    case RIGHT_KEY:
      this.keysPressed[RIGHT_KEY] = true
      break
    case UP_KEY:
      this.keysPressed[UP_KEY] = true
      break
    case DOWN_KEY:
      this.keysPressed[DOWN_KEY] = true
      break
    }

    if (this.directionKeyIsPressed()) {
      this.startMovingFromKey(0)

      if (this.selectedChildren.size > 0)
        evt.preventDefault()
    }
  }

  windowMonitorKeyup(evt) {
    if (this.fullyLocked())
      return

    const wasPressed = this.directionKeyIsPressed()
    switch(evt.keyCode) {
      case SHIFT_KEY:
        delete this.keysPressed[SHIFT_KEY]
        break
      case CTRL_KEY:
        delete this.keysPressed[CTRL_KEY]
        break
      case LEFT_KEY:
        delete this.keysPressed[LEFT_KEY]
        break
      case RIGHT_KEY:
        delete this.keysPressed[RIGHT_KEY]
        break
      case UP_KEY:
        delete this.keysPressed[UP_KEY]
        break
      case DOWN_KEY:
        delete this.keysPressed[DOWN_KEY]
        break
    }

    if (wasPressed && !this.directionKeyIsPressed()) {
      this.stopMovingFromKey()

      for (let child of this.selectedChildren)
        child.finishedKeyMove()
    }
  }

  isMultiselect() {
    return this.keysPressed[SHIFT_KEY] || this.keysPressed[CTRL_KEY]
  }

  moveChildren({x, y}) {
    for (let child of this.activeChildren)
      child.move({x, y})
  }

  boundingRect() {
    return this.container.current?.getBoundingClientRect() || {x: 0, y: 0}
  }

  resizeSelf(evt) {
    this.rect = this.boundingRect()
  }

  componentDidMount() {
    this.rect = this.boundingRect()

    this.scrollableAncestors = getScrollableAncestors(this.container.current)

    if (this.fullyLocked())
      return

    this.container.current?.addEventListener("mousedown", this.monitors.mouseDown)
    this.container.current?.addEventListener("mousemove", this.monitors.mouseMove)
    this.container.current?.addEventListener("mouseup", this.monitors.mouseUp)
    //this.container.current?.addEventListener("click", this.monitors.click)
    this.container.current?.addEventListener("drop", this.monitors.drop)
    this.container.current?.addEventListener("dragover", this.monitors.dragover)

    window.addEventListener("scroll", this.monitors.scroll)
    for (let ancestor of this.scrollableAncestors)
      ancestor.addEventListener("scroll", this.monitors.scroll)

    window.addEventListener("keydown", this.monitors.keydown)
    window.addEventListener("keyup", this.monitors.keyup)
    window.addEventListener("mouseup", this.monitors.mouseUp)
    window.addEventListener("resize", this.monitors.resize)
  }

  componentWillUnmount() {
    if (this.fullyLocked())
      return

    this.container.current?.removeEventListener("mousedown", this.monitors.mouseDown)
    this.container.current?.removeEventListener("mousemove", this.monitors.mouseMove)
    this.container.current?.removeEventListener("mouseup", this.monitors.mouseUp)
    //this.container.current?.removeEventListener("click", this.monitors.click)
    this.container.current?.removeEventListener("drop", this.monitors.drop)
    this.container.current?.removeEventListener("dragover", this.monitors.dragover)
    window.removeEventListener("keydown", this.monitors.keydown)
    window.removeEventListener("keyup", this.monitors.keyup)
    window.removeEventListener("mouseup", this.monitors.mouseUp)

    window.removeEventListener("scroll", this.monitors.scroll)
    for (let ancestor of this.scrollableAncestors)
      ancestor?.removeEventListener("scroll", this.monitors.scroll)

  }

  render() {
    return <div className="draw-area-component" ref={this.container}>
      { React.Children.map(this.props.children, child => this.renderDrawnRect(child)) }
    </div>
  }

  renderDrawnRect(child) {
    // Make a copy to keep the properties clean
    const props = { ... (child.props.rect || {}) }

    for (let prop of RECT_ATTRIBUTES)
      props[prop] = has(props, prop) ? props[prop] : child.props[prop]

    for (let prop of ['locked', 'active', 'drawOnCreate', 'lockHorizontalScale', 'lockVerticalScale'])
      props[prop] = child.props[prop]

    for (let func of componentCallbacks)
      props[func] = child.props[func]

    props.draw_context = this.api

    if (this.fullyLocked())
      props.locked = true
    return <DrawnRect {...props}>
      {child}
    </DrawnRect>
  }
}

export default DrawArea
