import React, { useCallback, useState, useMemo } from 'react'
import { useClasses } from 'lib/utilities/react'

const Input = React.memo(({ state, name, value = 1, offValue }) => {
  if (!state) return <input type="hidden" name={offValue ? name : null} value={offValue || ''} onChange={() => {}} />

  return <input type="hidden" name={name} value={value} onChange={() => {}} />
})

function Switch(props) {
  const { onChange, color = '#005C4F', onText = 'yes', offText = 'no' } = props

  const [state, setState] = useState(props.on)

  const classes = useClasses(['switch-utility', state ? 'on' : 'off'])

  const handleClick = useCallback(() => {
    setState(!state)
    onChange ? onChange(!state) : null
  }, [setState, onChange, state])

  const mainStyle = useMemo(
    () => ({
      borderColor: color,
      color: color,
    }),
    [color]
  )

  const onStyle = useMemo(
    () => ({
      borderRadius: '16px',
      color: 'white',
      background: color,
    }),
    [color]
  )

  return (
    <div className={classes} style={mainStyle} onClick={handleClick}>
      <Input state={state} {...props} />
      <div style={state ? null : onStyle}>{offText}</div>
      <div style={state ? onStyle : null}>{onText}</div>
    </div>
  )
}

export default Switch
