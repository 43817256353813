import React from 'react'
import BuildingSearch from 'components/utility/building_search'
import viaffinityLogo from '/app/assets/images/insurance/logos/viafinity.png'
import chubbLogo from '/app/assets/images/insurance/logos/chubb.png'

const SUGGESTIONS_URL = '/insurance/buildings/lookup_buildings'

function InsuranceBuildingSearch({ onBuildingSelect }) {
  return (
    <>
      <div className="dome-d-flex dome-flex-column dome-gap3 lookup-logos">
        <div className="dome-d-flex dome-align-center dome-gap3">
          <em>by</em>
          <img src={viaffinityLogo} style={{ width: '60px' }} />
        </div>
        <div className="dome-d-flex dome-align-center dome-gap3">
          <em>from</em>
          <img src={chubbLogo} style={{ width: '63px' }} />
        </div>
      </div>
      <div className="instant-quote-header dome-h3 dome-color-green-gradient">insurance for your home</div>
      <div className="search-wrapper dome-d-flex dome-flex-column">
        <div className="search-wrapper-text dome-p3" style={{ fontSize: '12px' }}>
          <div className="dome-d-flex dome-align-center dome-gap6">
            <i className="far fa-check dome-color-sage" />
            <span>matches building requirements</span>
          </div>
          <div className="dome-d-flex dome-align-center dome-gap6">
            <i className="far fa-check dome-color-sage" />
            <span>no personal info needed for estimate</span>
          </div>
          <div className="dome-d-flex dome-align-center dome-gap6">
            <i className="far fa-check dome-color-sage" />
            <span>policy auto uploads to library </span>
          </div>
        </div>
        <BuildingSearch
          show_empty_message={true}
          show_magnifying_glass={true}
          url={SUGGESTIONS_URL}
          classes={{
            iconClass: 'fa-light fa-magnifying-glass search-icon',
            containerClass: 'search-container',
            inputClass: 'search-container-search',
            searchBarBorderClass: 'searchbar-border-sm',
          }}
          placeholderText="find your property's estimate"
          onSelect={onBuildingSelect}
          customEmptyMessage={
            <>
              <div>
                It looks like insurance is not available for this property yet. For more information,{' '}
                <a
                  href="https://boardpackager.freshdesk.com/support/tickets/new"
                  target="_blank"
                  className="dome-link dome-link-aqua"
                >
                  contact us.
                </a>
              </div>
            </>
          }
        />
      </div>
    </>
  )
}

export default InsuranceBuildingSearch
