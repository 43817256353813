import React, { useCallback, useMemo, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import { sortObjectKeys, compactObject } from 'lib/utilities'
import { checkConditions } from 'lib/utilities/form'
import { useClasses } from 'lib/utilities/react'

import FieldType from './types'
import { toAbsolutePath } from '../../lib/utilities/form'
import { milliseconds } from 'date-fns'


function isEmpty(val) {
  if (!val)
    return true

  if (Array.isArray(val) && val.length == 0)
    return true

  if (typeof val == 'object' && Object.keys(val).length == 0)
    return true

  return String(val).match(/^\s*$/)
}

function Label({children, required}) {
  if (!children)
    return null

  return <label>
    {children}
    { required ? <span className="required">*</span> : null}
  </label>
}

function Error({error}) {
  if (isEmpty(error))
    return null

  let display = error
  if (Array.isArray(error))
    display = error.map((err, i) => <div key={i}>{err}</div>)
  else if (typeof error == 'object')
    display = Object.entries(error).map(([key, err]) => <div key={key}>{err}</div>)

  return <div className="error-message">
    {display}
  </div>
}

function lookupValue(value, path) {
  const type = typeof(value)

  // If it's a function, call it
  if (type == 'function')
    return value(path)

  // If the path not just a single key, return the value. The objects are not pathmaps
  if (path.includes("."))
    return value

  // If this is an object, return the next value in the object
  if (type == 'object' && value)
    return value[path]

  return value
}

function Fields(props) {
  const { field, error: errors } = props
  const { fields } = field
  const required = props.required || field.required

  const linked_fields = useMemo(() => (field.linked_fields || []).map(path => toAbsolutePath(path, props.id)), [field.linked_fields, props.id])
  const lookups = useGlobalPathmap(linked_fields, props.globalPathmap || {})

  const classes = useClasses([
    "form-field-object-component",
    props.className,
    isEmpty(errors) ? null : "has-errors"
  ])

  if (field.if && !checkConditions(field.if, lookups, props.id))
    return null

  function buildField(key) {
    const id =  `${props.id}.${key}`
    const field = { ... fields[key], required }
    const value = lookupValue(props.value, key)
    const error = lookupValue(errors, key)

    const fieldProps = {
      ...props, key, id, value, error, field
    }

    return <Field {...fieldProps} />
  }

  return <div className={classes}>
  { props.skipLabel ? null : <Label required={required}>{props.field.label}</Label> }
    <div className="fields">
      { sortObjectKeys(fields).map(buildField) }
    </div>
  </div>
}

function LeafField(props) {
  const { field, id, onChange, onBlur, locked } = props
  const { form_field = {}, options, system_only } = field || {}
  const { type } = form_field
  const required = props.required || field.required
  
  const linked_fields = useMemo(() => (field.linked_fields || []).map(path => toAbsolutePath(path, props.id)), [field.linked_fields, props.id])
  const lookups = useGlobalPathmap(linked_fields, props.globalPathmap || {})
  
  const value = lookupValue(props.value, id)
  const error = lookupValue(props.error, id)

  const typeProps = compactObject({
    id, value, options, field: form_field, required,
    onBlur, onChange, locked, system_only
  })

  const classes = useClasses([
    'form-field-component',
    type ? `form-field-${type}-component` : null,
    props.className,
    field.size ? `size-${field.size}` : null,
    isEmpty(error) ? null : 'has-error',
    props.isHorizontal ? "horizontal" : null
  ])

  if (field.if && !checkConditions(field.if, lookups, id))
    return null

  return <div className={classes}>
    { props.skipLabel ? null : <Label required={required}>{field.label}</Label> }
    <div className="field-items">
      <FieldType {...typeProps} />
      <Error error={error} />
    </div>
  </div>
}

function Field(props) {
  const { field } = props
  const { form_field } = field

  if (field.type != "object")
    return <LeafField {...props} />

  if (form_field?.type == "measure-and-units")
    return <LeafField {...props} />

  return <Fields { ...props } />
}

// We need to be cognizant of refreshes. There can be a *lot* of field and a lot of updating info.
export function useGlobalPathmap(paths, { getState, object, func}) {
  let values = useSelector( state => {
    if (typeof getState != "function") 
      return

    state = getState(state)
    return paths.map(path => state[path])
  }, shallowEqual)

  values ||= (object ? paths.map(path => object[path]) : null)
  values ||= (func ? paths.map(path => func(path)) : null)

  return useMemo(() => values ? Object.fromEntries(paths.map((path, index) => [path, values[index]])) : {}, values)
}

export default React.memo(Field)
